import { Component, OnInit, ViewChild, OnDestroy, NgZone, TemplateRef, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Request } from '../../shared/models/request/request';
import { RequestViewModel } from '../../shared/models/request/request-view-model';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../core/services/notification.service';
import { AppService } from '../../app.service';
import { ComponentPageBase } from '../../shared/components/component-page-base/component-page-base';
import { RequestsService } from '../../shared/services/requests.service';
import { LookupService } from '../../shared/services/lookup.service';
import { KeyValueObject } from '../../shared/models/core/KeyValueObject';
import { DataSourceRequestState, orderBy, State, GroupResult, groupBy, FilterDescriptor } from '@progress/kendo-data-query';
import { AgreementsService } from '../../shared/services/agreements.service';
import { MerchantsService } from '../../categories/merchants/merchants.service';
import { CustomerObjectsService } from '../../categories/customer-objects/customer-objects.service';
import { TidFullLookupModel } from '../../shared/models/tid/tid-full';
import { UploadFile } from '../../shared/models/attachment/upload-file';
import { DialogService } from '@progress/kendo-angular-dialog';
import { CustomerObjectForm } from '../../categories/customer-objects/customer-object/customer-object-form/customer-object.form';
import { SetPerformerUserGroup } from '../../shared/components/workflow-transitions/set-performer-user-group/set-performer-user-group';
import { WorkflowTransition } from '../../shared/models/request/workflow-transition';
import { ProjectWorkflowTransitionType } from '../../shared/models/request/workflow-transition-type';
import { SetPerformerUserGroupModel } from '../../shared/components/workflow-transitions/set-performer-user-group/set-performer.model';
import { ChangeStatusModel } from '../../shared/models/change-status-models/change-status-model';
import { PerformerUserGroupModel } from '../../shared/models/change-status-models/performer-user-group-model';
import { SetPerformerGroup } from '../../shared/components/workflow-transitions/set-performer-group/set-performer-group';
import { SetPerformerGroupModel } from '../../shared/components/workflow-transitions/set-performer-group/set-performer-group.model';
import { PerformerGroupModel } from '../../shared/models/change-status-models/performer-group-model';
import { UsersService } from '../../admin/users/users.service';
import { MerchantFormFull } from '../../categories/merchants/merchant/merchant-form-full/merchant.form.full';
import { Activity } from '../../shared/models/activity/activity';
import { ActivitiesService } from '../../shared/services/activities.service';
import { GridComponent, RowArgs, RowClassArgs, SelectableSettings } from '@progress/kendo-angular-grid';
import { AttachmentsService } from '../../shared/services/attachments.service';
import { UploadAttachmentsModel } from '../../shared/models/attachment/upload-attachments-model';
import { SetComment } from '../../shared/components/workflow-transitions/set-comment/set-comment';
import { SetCommentModel } from '../../shared/components/workflow-transitions/set-comment/set-comment.model';
import { SetReasonForStopping } from '../../shared/components/workflow-transitions/set-reason-for-stopping/set-reason-for-stopping';
import { ActivityOperation } from '../../shared/models/activity/activity-operation';
import { ActivityOperationsService } from '../../shared/services/activity-operations.service';
import { RequestDevicesComponent } from '../../shared/components/request-devices/request-devices.component';
import { ServiceCentersService } from '../../shared/services/service-centers.service';
import { StopRequestModel } from '../../shared/models/change-status-models/stop-request-model';
import { RequestChangeLog } from '../../shared/models/request/request-change-log';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { RequestServicesService } from '../../shared/services/request-services.service';
import { TidsService } from '../../shared/services/tids.service';
import { ActivityWorkflowTransition } from '../../shared/models/activity-workflow/activity-workflow-transition';
import { RequestStatus, RequestType } from '../../shared/enums';
import { ActivityType } from '../../shared/enums/activity-type.enum'

import { CheckDevicesAtWarehouseComponent, SetDeviceSerialNumbersComponent } from '../../shared/components/workflow-transitions';
import { delay, finalize, map, switchMap, take, tap } from 'rxjs/operators';

import { EngineerLookupModel } from '../../shared/models/engineers/engineer-lookup-model';
import { ServiceCenterEngineersData } from '../../shared/models/engineers/service-center-engineers-data';
import { ServiceCenterEngineers } from '../../shared/models/engineers/service-center-engineers';
import { Subject } from 'rxjs/Subject';
import { SecurityService } from '../../core/services/security.service';
import { NotificationType } from '../../core/services/notification-type';
import { RequestInitTypeEnum } from '../../shared/enums/request-init-type.enum';
import { ActivityStatusEnum } from '../../shared/enums/activity-status.enum';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivityWorkflowComponent } from '../request-components/activity-workflow/activity-workflow.component';
import { Contact } from '../../shared/models/contact/contact';
import { RequestUninstallDevicesService } from '../../shared/services/request-uninstall-devices.service';
import { RequestAttributeEnum } from '../../shared/enums/request-attribute.enum';
import { RequestNotificationsComponent } from '../request-components/request-notifications/request-notifications.component';
import { EditTidsComponent } from '../../shared/components/tid/edit-tids/edit-tids.component';
import { DropDownFilterSettings, MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { UserGroupsService } from '../../shared/services/user-groups.service';
import { RequestGroupDto } from '../../shared/models/request/request-group-dto';
import { MultipleOperationErrorResultComponent } from '../../shared/components/multiple-operations/multiple-operation-error-result/multiple-operation-error-result.component';
import { MultipleError } from '../../shared/models/request/multiple-error';
import { HttpEventType } from '@angular/common/http';
import { IntlService } from '@progress/kendo-angular-intl';
import { RequestType as RequestTypeEnum } from '../../shared/enums';
import { SetSerialNumbersFormDevice } from '../../shared/models/device/set-serial-numbers-form-device';
import { DeviceStatus } from '../../shared/enums/device-status.enum';
import { Attachment } from '../../shared/models/attachment/attachment';
import { saveAs } from 'file-saver';
import { CommentsBlockComponent } from '../../shared/components/comments/comments-block/comments-block.component';
import { CommentKindEnum } from '../../shared/enums/comment-kind.enum';
import { forkJoin, Observable } from 'rxjs';
import { TemplateEngineService } from '../../core/services/template-engine.service';
import { CustomerContact } from '../../shared/models/request/customer-contact';
import { LayoutService } from '../../layout/layout.service';
import { DaDataAddressComponent } from '../../shared/components/dadata-address/dadata-address.component';
import { WorkFlowStepTypeEnum } from '../../shared/enums/workflow-step-type.enum';
import { UserGroup } from '../../shared/models/user-group/user-group';
import { ExceptionCode } from '../../shared/enums/exception-code.enum';
import { AddressSuggestion } from '../../shared/models/dadata-address/address-suggestion';
import { EngubatorRequestsService } from '../../shared/services/engubator/engubator.requests.service';
import { EngubatorRequest } from '../../shared/models/engubator/engubator.request';
import { EntityViewModel } from '../../shared/models/core/EntityViewModel';
import { CustomerEnum } from '../../shared/enums/customer.enum';
import { TransitionConfirmationRequestComponent } from '../../shared/components/workflow-transitions/transition-confirmation-request/transition-confirmation-request.component';
import { CommentModel } from '../../shared/models/change-status-models/comment-model';
import { environment } from '../../../environments/environment';
import { ServiceCategoriesService } from '../../shared/services/service-categories.service';
import { ChangePwfStatusToastModel } from './request-models/change-pwf-status-toast.model';
import { SetCarrier } from '../../shared/components/workflow-transitions/set-carrier/set-carrier';
import { PerformerContact } from '../../shared/models/request/perfomer-contact';
import { KeyValueDataObject } from '../../shared/models/core/KeyValueDataObject';
import { ServiceAgreementInfo } from '../../shared/models/services/service-agreement-info';
import { ConfigurationPreparing } from '../../shared/components/workflow-transitions/configuration-preparing/configuration-preparing.component';
import { DeviceTypeEnum } from '../../shared/enums/device-type.enum';
import { ReserveResult } from '../../shared/enums/reserve-result.enum';
import { AddOrUpdateConfigurationModel } from '../../shared/components/workflow-transitions/configuration-preparing/add-or-update-configuration.model';
import { TmsConfigurationService } from '../../shared/services/tms-configuration.service';
import { ActivityFinesComponent } from '../request-components/activity-fines/activity-fines.component';
import { ActivityFinesService } from '../../shared/services/activity-fines/activity-fines.serivce';
import { ActivityFineTypesService } from '../../shared/services/activity-fines/activity-fine-types.service';
import { RequestPublicationSettingsService } from '../../shared/services/request-publication-settings.service';
import { EditingDismantledDevices } from '../../shared/components/workflow-transitions/editing-dismantled-devices/editing-dismantled-devices';
import { ContragentsService } from '../../categories/contragents/contragents.service';
import { NewRequestDevice } from '../../shared/models/request/new-request/new-request-device';
import { ServiceCenterLookupModel } from '../../shared/models/service-center/service-center-lookup';
import { SignalRNotificationsService } from '../../shared/services/signalr-notifications.service';
import { SetPlannedDateComponent } from '../../shared/components/workflow-transitions/set-planned-date/set-planned-date.component';
import { ExternalService } from '../../shared/services/external.service';
import { WarehousesService } from '../../shared/services/warehouses.service';

type checkSerialNumberAction = (transition: WorkflowTransition) => void;
declare var kendo: any;

@Component({
	selector: 'request',
	templateUrl: './request.page.html',
	styleUrls: [
		'../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../vendor/libs/ngx-contextmenu/ngx-contextmenu.scss',
		'./request.page.scss',
	]
})
export class RequestPage extends ComponentPageBase<Request> implements OnInit, OnDestroy {

	@ViewChild('commentBlock') public commentBlock: CommentsBlockComponent;
	@ViewChild('activityCommentsBlock') public activityComments: CommentsBlockComponent;
	@ViewChild('activityDevices') public activityDevices: RequestDevicesComponent;
	@ViewChild('activitiesGrid') public activitiesGrid: GridComponent;
	@ViewChild('activityWorkflow') public activityWorkflow: ActivityWorkflowComponent;
	@ViewChild("contragentContactsButtomSheetList") contragentContactsButtomSheetList: TemplateRef<any>;
	@ViewChild("legalEntity") legalEntity: ElementRef;
	@ViewChild("notifications") notifications: RequestNotificationsComponent;
	@ViewChild("tidList") tidList: MultiSelectComponent;
	@ViewChild("daDataLegalAddressControl") daDataLegalAddressControl: DaDataAddressComponent;
	@ViewChild("daDataRealAddressControl") daDataRealAddressControl: DaDataAddressComponent;
	@ViewChild('fines') public finesComponent: ActivityFinesComponent;

	nodes: any[] = [];

	gridHeight: number;

	entityIdInt: number;
	public entityViewModel: RequestViewModel;
	public engubatorViewModel: EntityViewModel<EngubatorRequest>;

	private serialNumbersFormDevices: SetSerialNumbersFormDevice[] = [];

	private requestUninstallDevices: NewRequestDevice[];

	private plannedDateSubject: Subject<{ date: Date, activity: Activity }> = new Subject();
	private engineerFilterSubject: Subject<string> = new Subject();
	private canChangeEngineerByActivity: boolean = true;
	private currentCustomerObjectAddress: string;
	private currentServiceCenterId: number;

	showActivitiesGrid: boolean = true;
	showActivityDetails: boolean = true;
	loadingEngineers: boolean = false;
	loadingContragents: boolean = false;
	contragentId: number;
	loadingActivities: boolean = false;
	mobileWorkflowsLoading: boolean = false;
	contragentContactsButtomSheetVisibility: boolean = false;

	public requestTypesEnum = RequestTypeEnum;

	public activitySelectableSettings: SelectableSettings = { checkboxOnly: false, mode: "single" };

	workflowTransitionsInProgress: number[] = [];
	activityTransitionsInProgress: number[] = [];

	requestTypes: KeyValueObject[] = [];
	requestStatuses: KeyValueObject[] = [];
	users: KeyValueObject[] = [];
	engineers: EngineerLookupModel[] = [];
	engineersGrouped: any[] = [];
	agreements: KeyValueObject[] = [];
	contragents: KeyValueObject[] = [];
	contragentsNotAllowedEmptyDesctiption: KeyValueObject[] = [];
	agreementServices: KeyValueDataObject<ServiceAgreementInfo[]>[] = [];
	performersByGroup: KeyValueObject[] = [];
	userGroups: UserGroup[] = [];
	authorsByGroup: KeyValueObject[] = [];
	agreementCategories: KeyValueObject[] = [];
	agreementServiceTypes: KeyValueObject[] = [];
	serviceCenters: KeyValueObject[] = [];
	customerObjectTids: TidFullLookupModel[] = [];
	operations: KeyValueObject[] = [];
	activityTypes: KeyValueObject[] = [];
	activityTypesForCreatingActivity: KeyValueObject[] = [];
	stoppingReasons: KeyValueObject[] = [];
	serviceCategories: KeyValueObject[] = [];
	allowedAgreementCategories: KeyValueObject[] = [];
	allowedAgreementServiceTypes: KeyValueObject[] = [];

	activities: Activity[] = [];
	changeLog: RequestChangeLog[] = [];
	defaultServiceCenter: ServiceCenterLookupModel = null;

	selectedActivities: number[] = [];
	selectedActivity: Activity = null;

	activeIdString: any = "main";
	uploadInProgress: any = false;
	uploadPercentages?: number;

	activeIdTransferString: any = "normal";

	terminalConfigAddedMessageListener = "terminalConfigAdded"

	public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

	merchantForm: MerchantFormFull;
	public dropDownListVirtualSettings: any = {
		itemHeight: 28
	};

	isProcessingNotifications: boolean = false;
	activityTypeReadOnly: boolean = false;
	isTreeSyncronized: boolean = false;

	public historyState: State = { sort: [{ field: 'changeDate', dir: 'desc' }] };

	public engineersDropDownVirtualSettings: any = { itemHeight: 56 };
	emptyEngineersMessage: string = "Не найдено инженеров";

	isAllArticlesEditing: boolean = false;

	public articlesFromDbDefaultItem: { id: number, name: string } = { id: null, name: 'Выберите артикул...' };

	get selectedActivityId(): number {
		if (this.selectedActivities.length === 0) {
			return null;
		}

		return this.selectedActivities[0];
	}

	get warehouseTransferRequestSerialNumberExists(): boolean {
		return this.entityViewModel.entity !== null
			&& this.entityViewModel.entity.requestDeviceWarehouseRefills !== null
			&& this.entityViewModel.entity.requestDeviceWarehouseRefills
				.some(s => s.requestSerialNumber !== null && s.requestSerialNumber.trim() !== "");
	}

	get isWarehouseTransfer(): boolean {
		return [RequestType.warehouseTransfer,
		RequestType.foreighWarehouseTransfer,
		RequestType.internalWarehouseTransfer].includes(this.entityViewModel.entity.requestTypeId);
	}

	get isWarehouseRefillNotMk(): boolean {
		return !this.isMkInitType && [RequestType.warehouseRefill].includes(this.entityViewModel.entity.requestTypeId);
	}

	get showMainTab(): boolean {
		return this.isSpecialRightClaimValid("Main") || this.securityService.isAdmin();
	}

	get showTspTab(): boolean {

		return (this.isSpecialRightClaimValid("Tsp") || this.securityService.isAdmin())
			&& !this.isCreateUserRequest;
	}

	get showEquipmentTab(): boolean {
		return this.isSpecialRightClaimValid("Equipment") || this.securityService.isAdmin();
	}

	get showInstructionsTab(): boolean {
		return this.isSpecialRightClaimValid("Instructions") || this.securityService.isAdmin();
	}

	get showFilesTab(): boolean {
		return (this.isSpecialRightClaimValid("Files") || this.securityService.isAdmin())
			&& this.securityService.hasClaim('cat-requestfiles-read');
	}

	get showServicesTab(): boolean {
		return this.isSpecialRightClaimValid("Services") || this.securityService.isAdmin();
	}

	get showActivitiesTab(): boolean {
		return this.isSpecialRightClaimValid("Activities") || this.securityService.isAdmin();
	}

	get showCallsTab(): boolean {
		return (this.isSpecialRightClaimValid("Calls") || this.securityService.isAdmin())
			&& this.securityService.hasClaim('cat-calls-read');
	}

	get showNotificationsTab(): boolean {
		return this.isSpecialRightClaimValid("Notifications") || this.securityService.isAdmin();
	}

	get showSlaStatusTab(): boolean {
		return this.isSpecialRightClaimValid("SlaStatus") || this.securityService.isAdmin();
	}

	get showChangesHistoryTab(): boolean {
		return this.isSpecialRightClaimValid("ChangesHistory") || this.securityService.isAdmin();
	}

	get showDeliveryTab(): boolean {
		return (this.isSpecialRightClaimValid("Delivery") || this.securityService.isAdmin())
			&& [RequestType.warehouseTransfer, RequestType.foreighWarehouseTransfer].includes(this.entityViewModel.entity.requestTypeId);
	}

	get isSameWarehouses(): boolean {
		if (!!this.entityViewModel.entity.senderWarehouseId
			&& !!this.entityViewModel.entity.recipientWarehouseId) {

			return this.entityViewModel.entity.senderWarehouseId === this.entityViewModel.entity.recipientWarehouseId;
		} else {
			return false;
		}
	}

	get onCheckWorkflowTransitions(): WorkflowTransition[] {
		const showListToIds = [
			RequestStatus.acceptanceDeviceByStorekeeper,
		];

		if (!this.appService.isMobileDevice) {
			showListToIds.push(RequestStatus.Verification);
		}

		if (!this.isEngineer) {
			showListToIds.push(RequestStatus.Assigned);
			showListToIds.push(RequestStatus.acceptanceDeviceByStorekeeperCompleted);
		}

		return this.entityViewModel.entity.workflowTransitions.filter(x => showListToIds.some(s => s === x.requestStatusToId));

	}

	get onMobileNewActionWorkflowTransitions(): WorkflowTransition[] {
		return this.entityViewModel.entity.workflowTransitions.filter(x => x.requestStatusToId === RequestStatus.Verification);
	}

	get isMobileDevice(): boolean {
		return this.appService.isMobileDevice;
	}

	public get showMobileView(): boolean {
		return this.isMobileDevice || this.securityService.isOnlyEngineer() || this.securityService.isOnlySCPartnerEngineer() || this.securityService.isSelfEmployedEngineer();
	}

	get activitiesGridHeight(): number {
		return this.isMobileDevice ? 500 : 300;
	}

	get isExchangeRequest(): boolean {
		return this.entityViewModel.entity.requestAttributeId === <number>RequestAttributeEnum.exchange;
	}

	get isProjectRequest(): boolean {
		return this.entityViewModel.entity.externalProjectId !== null && this.entityViewModel.entity.externalProjectId !== "";
	}

	get workingActivitiesExsists(): boolean {
		let workingActivityTypeIds = [<number>ActivityType.techSupport, <number>ActivityType.warehouse];

		return this.activities.some(s => workingActivityTypeIds.includes(s.activityTypeId)
			&& s.workFlowStepTypeId !== <number>WorkFlowStepTypeEnum.final);
	}

	get createOnlyUnknownDistanceActivityMk(): boolean {
		return this.isMkInitType
			&& this.entityViewModel.entity.partnerStatusAlias == 'takenToWorkSP'
			&& this.entityViewModel.entity.unknownDistance
			&& !!!this.entityViewModel.entity.lastActivityId;
	}

	constructor(
		private ngZone: NgZone,
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		private intl: IntlService,
		protected notificationService: NotificationService,
		protected dataService: RequestsService,
		private lookupService: LookupService,
		private agreementsService: AgreementsService,
		private merchantsService: MerchantsService,
		private requestsService: RequestsService,
		private tidsService: TidsService,
		private usersService: UsersService,
		private customerObjectsService: CustomerObjectsService,
		protected dialogService: DialogService,
		private activitiesService: ActivitiesService,
		private attachmentsService: AttachmentsService,
		private activityOperationsService: ActivityOperationsService,
		private serviceCentersService: ServiceCentersService,
		private requestUninstallDevicesService: RequestUninstallDevicesService,
		private requestServicesService: RequestServicesService,
		private securityService: SecurityService,
		private userGroupsService: UserGroupsService,
		private bottomSheet: MatBottomSheet,
		private templateEngineService: TemplateEngineService,
		private layoutService: LayoutService,
		private engubatorRequestService: EngubatorRequestsService,
		protected serviceCategoriesService: ServiceCategoriesService,
		private tmsConfigurationService: TmsConfigurationService,
		protected activityFinesService: ActivityFinesService,
		protected activityFineTypesService: ActivityFineTypesService,
		protected requestPublicationSettingsService: RequestPublicationSettingsService,
		private contragentsService: ContragentsService,
		private signalR: SignalRNotificationsService,
		private changeDetectorRef: ChangeDetectorRef,
		private externalService: ExternalService,
		private warehousesService: WarehousesService,
	) {
		super(route, router, appService, notificationService, dataService);
		this.listPageRoutePath = 'requests';
		this.appService.pageTitle = 'Новая заявка';
	}

	ngOnInit() {
		this.initData();
		this.gridHeight = window.innerHeight - 175;
	}

	dataBind(userViewModel: EntityViewModel<any>) {
		super.dataBind(userViewModel);
		this.currentCustomerObjectAddress = this.entityViewModel.entity.customerObjectAddress;
	}

	private initData(): void {
		this.entityId = this.route.snapshot.paramMap.get('requestId');
		this.entityIdInt = Number(this.entityId);

		this.initLookups();

		this.engineerFilterSubject.pipe(delay(1000)).subscribe(query => {

			this.loadingEngineers = true;
			const predicate = (item: EngineerLookupModel) => item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
			this.engineersGrouped = orderBy(<GroupResult[]>groupBy(this.engineers.filter(predicate), [{ field: "region" }]), [{ field: "value", dir: "asc" }]);
			this.loadingEngineers = false;

		});

		this.plannedDateSubject.pipe(delay(1000)).subscribe(data => {
			this.refreshEngineers(data.activity);
		});

		if (this.isMobileDevice) {
			this.showActivityDetails = false;
		}

		if (this.entityId != null) {

			this.route.queryParams.subscribe(params => {
				var activityId = params['activityId'];
				// console.log("activityId", activityId);

				if (activityId != null) {
					this.activeIdString = "activities-tab";
				}

				this.loadActivities(+this.entityId, activityId);
				setTimeout(() => this.fitActivitiesColumns(), 1000);
			});

			this.loadRequest(+this.entityId);

			this.loadGroupTree(+this.entityId);

			this.initSignalR(this.entityId);
		} else {
			this.canEditForm = true;
			this.isNew = true;
			this.entityViewModel = new RequestViewModel();
			this.entityViewModel.entity = new Request();
		}

	}

	loadGroupTree(requestId: number): void {
		this.dataService.getRequestGroup(requestId).subscribe(res => this.nodes = res);
	}

	get isShowGroupTree(): boolean {
		return this.groupTreeLogic || this.childTreeLogic;
	}

	get childTreeLogic(): boolean {
		return !!this.entityViewModel.entity.parentRequestId || this.entityViewModel.entity.childCount > 0;
	}

	get groupTreeLogic(): boolean {
		return this.entityViewModel.entity.isInGroupExt || this.entityViewModel.entity.isGroup;
	}

	get groupTreeCaption(): string {
		if (!this.isMkInitType) {
			return "Связанные перемещения";
		}

		if (this.groupTreeLogic) {
			return "Группа заявок";
		} else if (this.childTreeLogic) {
			return "Связанные с перемещением коллизии";
		}

		if (this.isMkInitType &&
			(this.entityViewModel.entity.requestTypeId == RequestType.warehouseTransfer ||
				this.entityViewModel.entity.requestTypeId == RequestType.confirmRepair ||
				this.entityViewModel.entity.requestTypeId == RequestType.foreighWarehouseTransfer))
			return "Связанные перемещения";
	}

	public get isByNomenclature(): boolean {
		return !this.isMkInitType && this.entityViewModel.entity.requestDeviceWarehouseRefills.some(s => !!s.nomenclature);
	}

	initLookups() {

		this.fillActivityTypes([
			{ id: <number>ActivityType.engineer, name: "Инженер", groupName: null, integraCode: null, disabled: false },
			{ id: <number>ActivityType.techSupport, name: "Тех. поддержка", groupName: null, integraCode: null, disabled: false },
			{ id: <number>ActivityType.warehouse, name: "Склад", groupName: null, integraCode: null, disabled: false }
		]);

		this.lookupService.getData("activity-types", null).subscribe(data => this.fillActivityTypes(data));
		this.lookupService.getData("request-types", null).subscribe(data => this.requestTypes = data);
		this.lookupService.getData("request-statuses", null).subscribe(data => this.requestStatuses = data);
		this.lookupService.getData("users", null).subscribe(data => this.users = data);
		this.lookupService.getData("operations", null).subscribe(data => this.operations = data);

		const scState: DataSourceRequestState = {
			filter: {
				logic: 'and', filters: [
					{ field: 'IsActual', operator: 'eq', value: true },
				]
			}
		};

		this.lookupService.getData("service-centers", scState).subscribe(data => {
			this.dataService.requestSc(+this.entityId).subscribe(scId => {
				if (!!scId && !data.some(s => s.id === scId)) {

					const scState2: DataSourceRequestState = {
						filter: {
							logic: 'and', filters: [
								{ field: 'ServiceCenterId', operator: 'eq', value: scId },
							]
						}
					};

					this.lookupService.getData("service-centers", scState2).subscribe(data2 => {
						let disElem = data2.find(f => f.id === scId);

						if (!!disElem) {
							disElem.disabled = true;
							this.serviceCenters = data;
							this.serviceCenters.push(disElem);
						}
					});
				} else {
					this.serviceCenters = data;
				}
			})

		});
		this.userGroupsService.getAll(this.entityIdInt).subscribe(data => this.userGroups = data);
		this.contragentsService.getContragnetsNotAllowedEmptyDescriprion().subscribe(data => this.contragentsNotAllowedEmptyDesctiption = data);
	}

	itemDisabledSc(itemArgs: { dataItem: KeyValueObject; index: number }) {
		return itemArgs.dataItem.disabled;
	}

	loadRequest(requestId: number, computeTspFields: boolean = true) {
		this.dataService.getById(requestId).subscribe(vm => {
			this.entityName = `${requestId}`;

			if (!!vm.entity.requestDeviceWarehouseRefills
				&& vm.entity.requestDeviceWarehouseRefills.length > 0) {

				vm.entity.requestDeviceWarehouseRefills.forEach(f => {
					if (!!f.comment) {
						f.commentObject = this.getCommentObject(f.comment);
					}

					f.isMkInitType = vm.entity.initTypeId === <number>RequestInitTypeEnum.blockChain;
				});
			}
			vm.entity.customerContacts.forEach(v => {
				if (v.contactPhone) {
					let len = v.contactPhone.length;
					v.maskedPhone = v.contactPhone.substring(0, len - 10) + '*******' + v.contactPhone.substring(len - 3);
				}
			});

			this.dataBind(vm);

			this.loadServiceCategories();

			this.processFinishDateFactLocal();

			this.loadAgreementsByClient(vm.entity.customerContragentId);
			this.loadMerchantsByClient(vm.entity.customerContragentId, computeTspFields);
			this.loadServiceCentersByCustomerObject(vm.entity.customerObjectId);
			//this.loadEngeneersByServiceCenter(vm.entity.serviceCenterId, new Date());
			this.loadTidsByByCustomerObject(vm.entity.customerObjectId);
			this.loadCustomerObjectsByMerchant(vm.entity.merchantId, computeTspFields);
			this.loadPerformerUsers(vm.entity.performerUserGroupId, false);
			this.authorUserGroupChange(vm.entity.authorUserGroupId, false);

			this.loadContragents(vm.entity.customerContragentId);

			this.lookupService.getData('stopping-reasons', null).subscribe(data => this.stoppingReasons = data);

			if (vm.entity.customerContragentId != null) {
				this.contragentId = vm.entity.customerContragentId;
			}

			if (vm.entity.customerContragentId !== <number>CustomerEnum.vtb &&
				(vm.entity.requestTypeId === RequestType.service
					|| vm.entity.requestTypeId === RequestType.serviceOrReplace)) {

				if (this.canSelectOnlyWarehouseActivityTypeMk
					|| this.canSelectOnlyWarehouseActivityTypeClassic
					|| (this.canSelectOnlyWarehouseActivityTypeUninstallNew && vm.entity.requestTypeId === RequestType.service)) {
					this.activityTypesForCreatingActivity = this.activityTypes
						.filter(x => x.id === <number>ActivityType.warehouse);

					this.activityTypeReadOnly = true;
				} else {
					this.activityTypesForCreatingActivity = this.activityTypes
						.filter(x => x.id === <number>ActivityType.engineer ||
							x.id === <number>ActivityType.techSupport);
				}

			} else {
				if (this.canSelectOnlyWarehouseActivityTypeMk
					|| this.canSelectOnlyWarehouseActivityTypeClassic
					|| this.canSelectOnlyWarehouseActivityTypePsbWarehouseRefill
					|| this.canSelectOnlyWarehouseActivityTypeAtmAllianceZenitWarehouseRefill
					|| this.canSelectOnlyWarehouseActivityTypeUninstallNew) {
					this.activityTypesForCreatingActivity = this.activityTypes
						.filter(x => x.id === <number>ActivityType.warehouse);

					this.activityTypeReadOnly = true;
				} else {
					this.activityTypesForCreatingActivity = this.activityTypes;

					this.activityTypeReadOnly = true;
				}
			}

			if (vm.entity.customerContragentId !== <number>CustomerEnum.vtb &&
				(vm.entity.requestTypeId === RequestType.install
					|| vm.entity.requestTypeId === RequestType.changeConfig
					|| vm.entity.requestTypeId === RequestType.installCRE
					|| vm.entity.requestTypeId === RequestType.service)
				&& this.activities.length > 0
				&& this.activities[this.activities.length - 1].activityTypeId == <number>ActivityType.engineer
				&& this.activities[this.activities.length - 1].statusId == <number>ActivityStatusEnum.awaitingDeliveryOfEquipment) {
				this.activityTypesForCreatingActivity = this.activityTypes
					.filter(x => x.id === <number>ActivityType.engineer ||
						x.id === <number>ActivityType.warehouse);
				this.activityTypeReadOnly = false;
			}


			if (this.entityViewModel.entity.performerUserGroupId != null && !this.userGroups.find(v => v.id == this.entityViewModel.entity.performerUserGroupId)) {
				this.userGroups.unshift({
					id: this.entityViewModel.entity.performerUserGroupId,
					name: this.entityViewModel.entity.performerUserGroupName,
					responsibleEmail: '',
					users: []
				});
			}

			this.getSenderAndRecipientWarehouseIds();
		});

		this.engubatorRequestService.getById(requestId).subscribe(vm => {
			this.engubatorViewModel = vm;
		})
	}

	loadContragents(contragentId?: number) {

		var state: DataSourceRequestState = null;

		if (contragentId != null) {
			state = {
				take: 1,
				filter: { logic: "and", filters: [{ field: "ContragentId", operator: "eq", value: contragentId }] }
			};
		}

		this.loadingContragents = true;

		this.lookupService.getData("contragents", state).subscribe(data => {
			this.contragents = data;
			this.loadingContragents = false;
		});
	}

	toggleFormEdit(): void {
		this.canEditForm = !this.canEditForm;
		this.processFinishDateFactLocal();

		if (this.canEditForm && this.contragents.length <= 1) {
			this.loadContragents(null);
		}

		if (this.canEditForm && this.entityViewModel.entity.customerContragentId != null) {
			this.contragentId = this.entityViewModel.entity.customerContragentId;
		}
		var multiselect = kendo.jQuery("#tidJs").data("kendoMultiSelect");
		if (multiselect != undefined) {
			if (this.canEditForm) {
				multiselect.readonly(false);
			}
			else {
				multiselect.readonly(true);
			}
		}
	}

	get isClientAlpha(): boolean {
		return this.entityViewModel.entity.customerContragentId === 4
			|| this.entityViewModel.entity.customerContragentId === 11222
			|| this.entityViewModel.entity.customerContragentId === 15772;
	}

	get isClientAlphaBank(): boolean {
		return this.entityViewModel.entity.customerContragentId === 4
	}

	fillActivityTypes(activityTypes: KeyValueObject[]) {
		this.activityTypes = activityTypes;
		this.activityTypesForCreatingActivity = this.activityTypes.filter(x => x.id === <number>ActivityType.engineer);
	}

	loadActivities(requestId: number, activityId?: number) {

		this.loadingActivities = true;

		this.dataService.getActivities(requestId).subscribe(res => {
			res.forEach(a => {
				a.createdDate = this.appService.parseDateTime(a.createdDate);
				a.planStartDate = this.appService.parseDateTime(a.planStartDate);
				a.dueDate = this.appService.parseDateTime(a.dueDate);
			});

			this.activities = orderBy(res, [{ field: "createdDate", dir: "desc" }]);

			if (this.isMobileDevice) {
				this.activities = this.activities.filter(a => a.activityTypeId === <number>ActivityType.engineer);
			}

			if (activityId != null) {
				this.showActivityById(activityId);
			} else if (this.activities.length > 0) {
				this.showActivityById(this.activities[0].activityId);
			}

			this.fitActivitiesColumns();

			if (this.activities && this.activities.length > 0) {
				let lastActivity = this.activities[0];
				switch (lastActivity.statusId) {
					case <number>ActivityStatusEnum.activityCreated:
					case <number>ActivityStatusEnum.visitConfirmed:
					case <number>ActivityStatusEnum.contactPersonNotRespond:
					case <number>ActivityStatusEnum.tspRefused:
					case <number>ActivityStatusEnum.tspNotReady:
					case <number>ActivityStatusEnum.visitWarehouse:
					case <number>ActivityStatusEnum.equipmentIsReadyForDelivery:
					case <number>ActivityStatusEnum.equipmentIssuedFromWarehouse:
					case <number>ActivityStatusEnum.awaitingDeliveryOfEquipment:
						this.canChangeEngineerByActivity = true;
						break;

					default:
						this.canChangeEngineerByActivity = false;
						break;
				}
			}

			this.loadingActivities = false;
		});
	}

	onChangeTids(selectedTids: KeyValueObject[]): void {
		if (selectedTids.length === 0) {
			this.clearTsp();
			this.entityViewModel.entity.acceptedTidNumbers = [];
		} else {
			const tid = selectedTids[0].name.split('|')[0].trim();
			this.tidsService.getTidByTidNumber(tid)
				.subscribe(result => {
					this.entityViewModel.entity.merchantId = result.merchantId;
					this.entityViewModel.entity.customerObjectId = result.customerObjectId;

					this.customerObjectsService.getById(result.customerObjectId)
						.subscribe(result => {
							this.entityViewModel.entity.customerObjectName = result.entity.name;
						});

					this.loadMerchantsByClient(this.entityViewModel.entity.customerContragentId);

					this.bindTspComputedFields();
					setTimeout(() => {
						if (this.daDataLegalAddressControl && this.daDataRealAddressControl) {
							this.daDataLegalAddressControl.initValue(null);
							this.daDataRealAddressControl.initValue(null);
						}
					}, 1000);
				});

			this.entityViewModel.entity.acceptedTidNumbers = selectedTids;
		}
	}

	tabChanged(e: NgbTabChangeEvent) {
		if (e.nextId === "change-log-tab") {
			this.loadChangeLog();
		} else if (e.nextId === "activities-tab") {
			this.fitActivitiesColumns();
			this.onSelectedActivityChange();
		} else if (e.nextId === 'tsp') {
			setTimeout(() => {
				if (this.daDataLegalAddressControl && this.daDataRealAddressControl) {
					this.daDataLegalAddressControl.initValue(this.entityViewModel.entity.merchantContragentAddress);
					this.daDataRealAddressControl.initValue(this.entityViewModel.entity.customerObjectAddress);
				}
				var url = `${environment.apiUrl}v1/agreements/${this.entityViewModel.entity.agreementId}/tids`;
				var authUser = localStorage.getItem("authUser");
				var header;
				if (authUser) {
					header = `Bearer ${JSON.parse(authUser).bearerToken}`
				};
				var parentThis = this;
				kendo.jQuery("#tidJs").kendoMultiSelect({
					change: function (e) {
						var value = this.dataItems();
						parentThis.entityViewModel.entity.acceptedTidNumbers = [];
						value.forEach(f => {
							parentThis.entityViewModel.entity.acceptedTidNumbers.push(new KeyValueObject(f.id, f.name));
						});
						parentThis.appService.MarkFormDirty(parentThis.entityForm);
						parentThis.onChangeTids(parentThis.entityViewModel.entity.acceptedTidNumbers);
					},
					open: function (e) {
						if (!!!this.dataSource.options.transport) {
							this.setDataSource({
								transport: {
									read: {
										url: url,
										beforeSend: function (req) {
											req.setRequestHeader('Authorization', header);
										},
									}
								},
								schema: {
									type: "json",
									data: "data",
									total: "total"
								},
								pageSize: 50,
								serverPaging: true,
								serverFiltering: true
							})
						}
					},
					placeholder: "Выберите Tid...",
					dataTextField: "name",
					dataValueField: "id",
					height: 300,
					virtual: {
						itemHeight: 24,
						mapValueTo: "dataItem",
						valueMapper: function (options) {
							var result = [];
							options.value.forEach(f => {
								var index = parentThis.entityViewModel.entity.acceptedTidNumbers.map(function (x) { return x.id; }).indexOf(f);
								var data = parentThis.entityViewModel.entity.acceptedTidNumbers[index];
								result.push({ id: data.id, name: data.name });
							});
							options.success(result);
						}
					},
					filter: "contains",
					delay: 1000,
				});
				var multiselect = kendo.jQuery("#tidJs").data("kendoMultiSelect");
				if (!this.canEditForm && multiselect != undefined) {
					multiselect.readonly(true);
				}
				var selectedData = [];
				this.entityViewModel.entity.acceptedTidNumbers.forEach(f => {
					selectedData.push({ id: f.id, name: f.name });
				});
				if (multiselect) {
					multiselect.value(selectedData);
				}
			}, 1000);
		}

		this.activeIdString = e.nextId;
	}

	tabTransferChanged(e: NgbTabChangeEvent) {
		// if (e.nextId === "summary") {
		// 	this.loadChangeLog();
		// } else if (e.nextId === "activities-tab") {
		// 	this.fitActivitiesColumns();
		// }
	}

	loadChangeLog() {
		this.dataService.getChangeLog(this.entityViewModel.entity.requestId).subscribe(res => {
			this.changeLog = res;
		});
	}

	public workflowTransitionClick(transition: WorkflowTransition) {
		if (transition.errorText) {
			this.notificationService.error({
				title: 'Запрещенный переход',
				message: transition.errorText,
				notificationType: NotificationType.SweetAlert
			});
			return;
		}

		if (transition.requestStatusFromId === RequestStatus.WorkCompleted && transition.requestStatusToId === RequestStatus.Verification && this.entityViewModel.entity.requestTypeId === RequestType.uninstall) {
			this.switchOnTranslationFlag(transition.transitionId);
			this.requestUninstallDevicesService.hasAttachmentCount(this.entityViewModel.entity.requestId)
				.pipe(finalize(() => this.switchOffTranslationFlag(transition.transitionId)))
				.subscribe((has: boolean) => {
					if (!has) {
						this.notificationService.error({
							title: 'Проверка фотографий',
							message: 'Добавьте фотографии к оборудованию  в заявке',
							notificationType: NotificationType.SweetAlert
						});
					} else {
						this.changeStatus(transition);
					}
				});
		} else {
			this.changeStatus(transition);
		}
	}

	private changeStatus(transition: WorkflowTransition) {
		const performerUserGroupId = this.entityViewModel.entity.performerUserGroupId;

		switch (transition.projectWorkflowTransitionType) {
			case ProjectWorkflowTransitionType.SetPerformerUserAndGroupTransition:

				const dialogRef1 = this.dialogService.open({
					title: `Переход в статус '${transition.transitionName}'`,
					content: SetPerformerUserGroup,
					width: 400,
				});

				const setPerformerUserGroupForm = <SetPerformerUserGroup>dialogRef1.content.instance;
				setPerformerUserGroupForm.setPerformerUserGroupModel = new SetPerformerUserGroupModel(performerUserGroupId, this.entityViewModel.entity.performerUserId);
				setPerformerUserGroupForm.setPerformerUserGroupModel.commentKind = CommentKindEnum.internal;
				setPerformerUserGroupForm.workflowTransition = transition;
				setPerformerUserGroupForm.requestId = this.entityIdInt;

				setPerformerUserGroupForm.onContinueEvent.subscribe((result: SetPerformerUserGroupModel) => {
					this.entityViewModel.entity.performerUserGroupId = result.userGroupId;
					this.entityViewModel.entity.performerUserId = result.userId;

					this.changeStatusCore(transition, new PerformerUserGroupModel(this.entityViewModel.entity.requestId, transition.requestStatusToId, this.entityViewModel.entity.performerUserId, this.entityViewModel.entity.performerUserGroupId, result.comment, result.commentKind));
				});

				break;

			case ProjectWorkflowTransitionType.SetPerformerGroupTransition:

				const dialogRef2 = this.dialogService.open({
					title: `Переход в статус '${transition.transitionName}'`,
					content: SetPerformerGroup,
					width: 400,
				});

				const setPerformerGroupForm = <SetPerformerGroup>dialogRef2.content.instance;
				setPerformerGroupForm.setPerformerGroupModel = new SetPerformerGroupModel(performerUserGroupId);
				setPerformerGroupForm.setPerformerGroupModel.commentKind = CommentKindEnum.internal;
				setPerformerGroupForm.workflowTransition = transition;

				setPerformerGroupForm.requestId = this.entityIdInt;

				setPerformerGroupForm.onContinueEvent.subscribe((result: SetPerformerGroupModel) => {
					this.entityViewModel.entity.performerUserGroupId = result.userGroupId;

					this.changeStatusCore(transition, new PerformerGroupModel(this.entityViewModel.entity.requestId, transition.requestStatusToId, this.entityViewModel.entity.performerUserGroupId, result.comment, result.commentKind));
				});

				break;

			case ProjectWorkflowTransitionType.SetReasonForStopping:
				const dialogRef3 = this.dialogService.open({
					title: `Переход в статус '${transition.transitionName}'`,
					content: SetReasonForStopping,
					width: 400
				});

				const setStopReasonAndDateForm = <SetReasonForStopping>dialogRef3.content.instance;
				setStopReasonAndDateForm.setPerformerUserGroupModel = new SetPerformerUserGroupModel(performerUserGroupId, this.entityViewModel.entity.performerUserId);
				setStopReasonAndDateForm.setPerformerUserGroupModel.commentKind = CommentKindEnum.internal;
				setStopReasonAndDateForm.userGroups = this.userGroups;
				setStopReasonAndDateForm.requestId = this.entityIdInt;

				if (!this.isMkInitType) {
					setStopReasonAndDateForm.requestCustomerContragentId = this.entityViewModel.entity.customerContragentId;
				}

				setStopReasonAndDateForm.workflowTransition = transition;
				setStopReasonAndDateForm.stoppingReasons = this.stoppingReasons;
				if (this.entityViewModel.entity.stoppedUntilLocal) {
					setStopReasonAndDateForm.stoppedUntil = this.appService.parseDateTime(this.entityViewModel.entity.stoppedUntilLocal);
				}
				setStopReasonAndDateForm.isClientAlfaBank = this.isClientAlphaBank;
				setStopReasonAndDateForm.onContinueEvent.subscribe((result: SetPerformerUserGroupModel) => {
					this.entityViewModel.entity.performerUserGroupId = result.userGroupId;
					this.entityViewModel.entity.performerUserId = result.userId;
					if (setStopReasonAndDateForm.stoppingReason) {
						result.requestStoppingReasonId = setStopReasonAndDateForm.stoppingReasons.find((v, i, a) => v.id === setStopReasonAndDateForm.stoppingReason).id;
						result.comment = result.comment ? 'Комментарий: ' + result.comment : null;
					}

					if (!!setStopReasonAndDateForm.stoppedUntil) {
						setStopReasonAndDateForm.stoppedUntil.setHours(setStopReasonAndDateForm.stoppedUntil.getHours() - this.entityViewModel.entity.utcTimezoneShift);
					}

					this.changeStatusCore(transition, new StopRequestModel(
						this.entityViewModel.entity.requestId,
						transition.requestStatusToId,
						this.entityViewModel.entity.performerUserId,
						this.entityViewModel.entity.performerUserGroupId,
						result.comment,
						setStopReasonAndDateForm.stoppedUntil,
						result.commentKind,
						result.requestStoppingReasonId));
				});
				break;

			case ProjectWorkflowTransitionType.setDeviceSerialNumbers:
				this._setDeviceSerialNumbers(transition);
				break;

			case ProjectWorkflowTransitionType.deviceToWarehouseAction:
				this._checkDevicesAtWarehouse(transition, DeviceStatus.deInstalled, true);
				break;

			case ProjectWorkflowTransitionType.receivingDeviceAtWarehouseAction:
				this._checkDevicesAtWarehouse(transition, DeviceStatus.deliveredToWarehouse, true);
				break;

			case ProjectWorkflowTransitionType.transitionConfirmationRequest:
				this.transitionConfirmationRequest(transition);
				break;

			case ProjectWorkflowTransitionType.showToast:
				let changePwfStatusToastModel: ChangePwfStatusToastModel = null;
				if (!!transition.warningText) {
					changePwfStatusToastModel = new ChangePwfStatusToastModel(true, true, null, transition.warningText, null, null)
				}

				this.changeStatusCore(transition,
					new ChangeStatusModel(this.entityViewModel.entity.requestId, transition.requestStatusToId,
						null,
						null,
						null,
						null,
						false,
						false,
						CommentKindEnum.internal,
						transition.transitionId),
					changePwfStatusToastModel
				);
				break;

			case ProjectWorkflowTransitionType.choosingTransportCompanyAndSpecifyingTrackNumber:
				const dialogRef = this.dialogService.open({
					content: SetCarrier,
					width: this.isMobileDevice ? '90%' : '70%'
				});

				const setCarrierForm = <SetCarrier>dialogRef.content.instance;

				setCarrierForm.requestId = this.entityViewModel.entity.requestId;
				break;

			case ProjectWorkflowTransitionType.configurationPreparingForTransferToTMSGate:
				this.transitionConfigurationPreparing(transition);
				break;

			case ProjectWorkflowTransitionType.editingDismantledDevices:
				this.transitionEditingDismantledDevicesPreparing(transition);
				break;

			case ProjectWorkflowTransitionType.planned:
				this.transitionSetPlannedDate(transition);
				break;

			default:
				this.changeStatusCore(transition, new ChangeStatusModel(this.entityViewModel.entity.requestId, transition.requestStatusToId));
				break;
		}
	}

	private transitionEditingDismantledDevicesPreparing(transition: WorkflowTransition) {
		const transitionEditingDismantledDevicesPreparingRef = this.dialogService.open({
			title: `Переход в статус '${transition.transitionName}'`,
			content: EditingDismantledDevices,
			width: '70%'
		});

		const transitionEditingDismantledDevicesPreparingDialog = <EditingDismantledDevices>transitionEditingDismantledDevicesPreparingRef.content.instance;
		transitionEditingDismantledDevicesPreparingDialog.requestId = this.entityViewModel.entity.requestId;
		transitionEditingDismantledDevicesPreparingDialog.serviceCenterId = this.entityViewModel.entity.serviceCenterId;
		transitionEditingDismantledDevicesPreparingDialog.contragentId = this.entityViewModel.entity.customerContragentId;
		transitionEditingDismantledDevicesPreparingDialog.contragentIntegraCode = this.entityViewModel.entity.customerContragentIntegraCode;
		transitionEditingDismantledDevicesPreparingDialog.onContinueEvent.subscribe(() => {
			this.changeStatusCore(transition, new ChangeStatusModel(this.entityViewModel.entity.requestId, transition.requestStatusToId));
		});
	}

	private transitionConfigurationPreparing(transition: WorkflowTransition) {
		let deviceTypesToConfiguration = [<number>DeviceTypeEnum.mobileTerminal, <number>DeviceTypeEnum.terminal, <number>DeviceTypeEnum.intelligentPinPad, <number>DeviceTypeEnum.pos];
		let deviceReserveResultsToConfiguration = [<number>ReserveResult.reserveRequest, <number>ReserveResult.booked, <number>ReserveResult.awaitingTransferToServiceCenterWarehouse, <number>ReserveResult.pendingIssueToEngineer, <number>ReserveResult.receivingEngineer, <number>ReserveResult.equipmentReceivedByEngineer];

		this.requestsService.getRequestDevices(this.entityViewModel.entity.requestId).subscribe(data => {

			let requestDevicesToConfiguration = data.filter(d => deviceTypesToConfiguration.includes(d.deviceTypeId) && deviceReserveResultsToConfiguration.includes(d.reserveResultId))
			if ((this.entityViewModel.entity.requestTypeId == RequestTypeEnum.service)
				&& requestDevicesToConfiguration.length == 0) {
				this.requestsService.getRequestDevicesByTid(this.entityViewModel.entity.requestId).subscribe(data => {
					let requestDevicesToConfiguration = data.filter(d => deviceTypesToConfiguration.includes(d.deviceTypeId))
					this.openTransitionConfiguration(transition, requestDevicesToConfiguration);
				});
			}
			else {
				this.openTransitionConfiguration(transition, requestDevicesToConfiguration);
			}
		});
	}

	private openTransitionConfiguration(transition: WorkflowTransition, requestDevicesToConfiguration: NewRequestDevice[]) {
		const transitionConfigurationPreparingRef = this.dialogService.open({
			title: `Переход в статус '${transition.transitionName}'`,
			content: ConfigurationPreparing,
			width: '70%'
		});

		const transitionConfigurationPreparingDialog = <ConfigurationPreparing>transitionConfigurationPreparingRef.content.instance;
		transitionConfigurationPreparingDialog.request = this.entityViewModel.entity;
		transitionConfigurationPreparingDialog.requestDevice = requestDevicesToConfiguration.length > 0 ? requestDevicesToConfiguration[0] : new NewRequestDevice();
		transitionConfigurationPreparingDialog.stoppingReasons = this.stoppingReasons;
		transitionConfigurationPreparingDialog.onContinueEvent.subscribe((result: AddOrUpdateConfigurationModel) => {
			this.notificationService.success({
				title: 'Обновление конфигурации',
				message: "Статус заявки и комментарии скоро обновятся!",
				notificationType: NotificationType.SweetAlert
			});
			this.changeStatusCore(transition, new ChangeStatusModel(this.entityViewModel.entity.requestId, transition.requestStatusToId), null, result);
		});
	}

	private transitionConfirmationRequest(transition: WorkflowTransition) {
		const transitionConfirmationRequestRef = this.dialogService.open({
			title: `Переход в статус '${transition.transitionName}'`,
			content: TransitionConfirmationRequestComponent,
			width: 700
		});

		const transitionConfirmationRequestDialog = <TransitionConfirmationRequestComponent>transitionConfirmationRequestRef.content.instance;

		transitionConfirmationRequestDialog.setCommentModel = new SetCommentModel('');
		transitionConfirmationRequestDialog.setCommentModel.commentKind = CommentKindEnum.internal;

		transitionConfirmationRequestDialog.workflowTransition = transition;

		transitionConfirmationRequestDialog.onContinueEvent.subscribe((result: SetCommentModel) => {
			this.changeStatusCore(transition, new CommentModel(this.entityViewModel.entity.requestId, transition.requestStatusToId, result.commentText, result.commentKind));
		});
	}

	private transitionSetPlannedDate(transition: WorkflowTransition) {
		const transitionConfirmationRequestRef = this.dialogService.open({
			title: `Переход в статус '${transition.transitionName}'`,
			content: SetPlannedDateComponent
		});

		const transitionConfirmationRequestDialog = <SetPlannedDateComponent>transitionConfirmationRequestRef.content.instance;
		transitionConfirmationRequestDialog.requestId = this.entityViewModel.entity.requestId;
		transitionConfirmationRequestDialog.onContinueEvent.subscribe(() => {
			this.changeStatusCore(transition, new ChangeStatusModel(+this.entityId, transition.requestStatusToId))
		});
	}

	private _checkDevicesAtWarehouse(transition: WorkflowTransition, deviceStatus: DeviceStatus, loadDevices: boolean = false): void {

		if (loadDevices) {
			this.requestsService.getRequestUninstallDevices(this.entityViewModel.entity.requestId)
				.subscribe(result => {
					this.requestUninstallDevices = result;
					this.serialNumbersFormDevices = result
						.filter(x => x.deviceStatusId === <number>deviceStatus)
						.map(device => new SetSerialNumbersFormDevice(device));
					this.showCheckDevicesAtWarehouseForm(transition);
				});
		} else {
			this.showCheckDevicesAtWarehouseForm(transition);
		}

	}

	private showCheckDevicesAtWarehouseForm(transition: WorkflowTransition) {

		const serialNumberFormDevice = this.serialNumbersFormDevices.filter(x => !x.processed);

		let shortcutReturnEquipment = false;

		var hasWarehouseActivity = this.activities.some(a => a.activityTypeId === <number>ActivityType.warehouse);
		if (this.entityViewModel.entity.isOneRoleSC
			&& this.selectedActivity.activityTypeId == ActivityType.engineer
			&& !hasWarehouseActivity) {
			shortcutReturnEquipment = true;
		}

		const dialogRef = this.dialogService.open({
			title: `Переход в статус '${transition.transitionName}'`,
			content: CheckDevicesAtWarehouseComponent,
			width: this.appService.isMobileDevice ? '90%' : 600
		});

		const instance = <CheckDevicesAtWarehouseComponent>dialogRef.content.instance;
		instance.newActivityStatusId = transition.requestStatusToId;
		instance.requestId = +this.entityId;
		instance.shortcutReturnEquipment = shortcutReturnEquipment;
		instance.device = serialNumberFormDevice.length > 0 ? serialNumberFormDevice[0].device : null;
		instance.serviceCenterId = this.entityViewModel.entity.serviceCenterId;
		instance.warehouseId = this.entityViewModel.entity.recipientWarehouseId;
		instance.showWarehouseForm = transition.projectWorkflowTransitionType === ProjectWorkflowTransitionType.receivingDeviceAtWarehouseAction && this.entityViewModel.entity.initTypeId !== 3;

		instance.continueEmitter
			.subscribe(result => {
				if (shortcutReturnEquipment) {
					this.shortcutReturnEquipment(transition);
				} else {
					this.changeStatusCore(transition, new ChangeStatusModel(+this.entityId, transition.requestStatusToId))
				}
				if (result.showWarehouseForm) {
					this.entityViewModel.entity.recipientWarehouseId = result.recipientWarehouseId;
				}
			});

		instance.checkNextSerialNumberEmitter
			.subscribe(result => {
				if (serialNumberFormDevice.length > 0) {
					serialNumberFormDevice[0].processed = true;
				}

				if (result.showWarehouseForm) {
					this.entityViewModel.entity.recipientWarehouseId = result.recipientWarehouseId;
				}

				if (serialNumberFormDevice.length === 0) {
					this.changeStatusCore(transition, new ChangeStatusModel(this.entityViewModel.entity.requestId, transition.requestStatusToId))
				} else {
					this._showSNConfirmation(transition, this._checkDevicesAtWarehouse.bind(this));
				}

			});
	}

	private _showSNConfirmation(transition: WorkflowTransition, checkSerialNumber: checkSerialNumberAction): void {
		this.notificationService
			.confirmation({
				message: 'Есть ещё оборудование для проверки серийного номера?',
				type: 'question',
				confirmButtonText: 'Да',
				cancelButtonText: 'Нет',
				showCloseButton: true
			}, () => checkSerialNumber(transition),
				() => this.changeStatusCore(transition, new ChangeStatusModel(this.entityViewModel.entity.requestId, transition.requestStatusToId)));
	}

	private _setDeviceSerialNumbers(transition: WorkflowTransition): void {
		const setDeviceSerialNumbersDialogRef = this.dialogService.open({
			title: `Переход в статус '${transition.transitionName}'`,
			content: SetDeviceSerialNumbersComponent,
			width: 600
		});

		const setDeviceSerialNumbersForm = <SetDeviceSerialNumbersComponent>setDeviceSerialNumbersDialogRef.content.instance;
		setDeviceSerialNumbersForm.requestId = +this.entityId;
		setDeviceSerialNumbersForm.continueEmitter
			.subscribe(() =>
				this.changeStatusCore(transition, new ChangeStatusModel(this.entityViewModel.entity.requestId, transition.requestStatusToId))
			);
		setDeviceSerialNumbersForm.checkNextSerialNumberEmitter
			.subscribe(() => this._showSNConfirmation(transition, this._setDeviceSerialNumbers.bind(this)));
	}

	eventProcessedClick(): void {
		this.isProcessingNotifications = true;
		this.requestsService.markAsReadRequestNotifications(this.entityId)
			.subscribe(result => {
				if (result.isSuccessful) {
					this.notifications.loadNotifications();
				}

				this.isProcessingNotifications = false;
			});
	}

	changeStatusCore(transition: WorkflowTransition, changeStatusModel: any, changePwfStatusToastModel: ChangePwfStatusToastModel = null, addOrUpdateConfigurationModel: AddOrUpdateConfigurationModel = null) {
		if (transition.requestStatusToId === <number>RequestStatus.openPublication) {
			this.tryChangeStatusToOpenPublication(transition, changeStatusModel, changePwfStatusToastModel, addOrUpdateConfigurationModel);
		} else {
			this.changeStatusCoreBase(transition, changeStatusModel, changePwfStatusToastModel, addOrUpdateConfigurationModel);
		}
	}

	tryChangeStatusToOpenPublication(transition: WorkflowTransition, changeStatusModel: any, changePwfStatusToastModel: ChangePwfStatusToastModel = null, addOrUpdateConfigurationModel: AddOrUpdateConfigurationModel = null) {
		this.dataService.checkChangeStatusToOpenPublication(changeStatusModel.requestId, changeStatusModel.newStatusId).subscribe(resp => {
			if (resp.data.newStatusId == RequestStatus.Assigned) {
				changeStatusModel.newStatusId = RequestStatus.Assigned;
				changeStatusModel.forceChangeStatus = true;

				this.requestsService.addComment({
					text: resp.data.message,
					requestId: this.entityViewModel.entity.requestId,
					activityId: null,
					uploadAttachments: [],
					notifyCustomer: false,
					notifyEngineer: false,
					engineerNotifyWays: [],
					commentKind: CommentKindEnum.system
				}).subscribe(x => { });
			}
			else {
				changeStatusModel.openPublicationStateChecked = true;
			}

			if (resp.data.asError) {
				this.notificationService.error({
					title: "Переход",
					message: resp.data.message,
					notificationType: NotificationType.SweetAlert
				});
			}
			else {
				this.notificationService.info(
					{
						title: "Переход",
						message: resp.data.message,
						showConfirmButton: true,
						showCloseButton: false,
						timer: 5000,
						notificationType: NotificationType.SweetAlert
					});
			}

			this.changeStatusCoreBase(transition, changeStatusModel, changePwfStatusToastModel, addOrUpdateConfigurationModel);
		});
	}

	isAlpha() {
		return this.entityViewModel.entity.customerContragentId == CustomerEnum.alpha ||
			this.entityViewModel.entity.customerContragentId == CustomerEnum.alphaAlliance;
	}

	changeStatusCoreBase(transition: WorkflowTransition, changeStatusModel: any, changePwfStatusToastModel: ChangePwfStatusToastModel = null, addOrUpdateConfigurationModel: AddOrUpdateConfigurationModel = null) {
		if (changeStatusModel.newStatusId == RequestStatus.ConfigurationPreparing && !addOrUpdateConfigurationModel && this.isAlpha()) {
			this.notificationService.error({
				title: "Смена статуса",
				message: "Ошибка смены статуса на Подготовка конфигурации. Объект конфигурации не заполнен. Смена статуса невозможна.",
				notificationType: NotificationType.Toast
			});
		}
		else {
			if (this.appService.isMobileDevice && transition.requestStatusToId === RequestStatus.Verification) {
				this.activityWorkflow.activitiesButtomSheetListCancel(null);
				this.mobileWorkflowsLoading = true;
			}
			this.switchOnTranslationFlag(transition.transitionId);
			changeStatusModel.transitionId = transition.transitionId
			this.dataService
				.changeStatus(changeStatusModel)
				.subscribe(x => {

					this.switchOffTranslationFlag(transition.transitionId);

					if (x.isSuccessful) {
						this.dataService.getById(this.entityViewModel.entity.requestId).subscribe(vm => {
							if (vm != null && vm.entity != null && vm.entity.createNewSelfEmployedAccountCommand != null) {
								vm.entity.createNewSelfEmployedAccountCommand.deRegistrationDate = new Date(vm.entity.createNewSelfEmployedAccountCommand.deRegistrationDate);
								vm.entity.createNewSelfEmployedAccountCommand.registrationDate = new Date(vm.entity.createNewSelfEmployedAccountCommand.registrationDate);
								vm.entity.createNewSelfEmployedAccountCommand.passportDate = new Date(vm.entity.createNewSelfEmployedAccountCommand.passportDate);
							}
							this.dataBind(vm);
							if (vm.entity.requestTypeId == RequestTypeEnum.registration && vm.entity.statusId == RequestStatus.Completed) {
								this.commentBlock.loadComments(300, this.getLastActivityPerformerUserId());
							}
							else if (!!changeStatusModel.comment) {
								this.commentBlock.loadComments(300, this.getLastActivityPerformerUserId());
							}
						});

						this.loadActivities(this.entityViewModel.entity.requestId);

						this.mobileWorkflowsLoading = false;

						if (!!changePwfStatusToastModel && !!changePwfStatusToastModel.showSuccess) {
							this.notificationService.success({
								title: changePwfStatusToastModel.successTitle,
								message: changePwfStatusToastModel.successMessage,
								notificationType: NotificationType.Toast
							});
						}

						if (!!addOrUpdateConfigurationModel) {
							this.tmsConfigurationService.addOrUpdateConfiguration(addOrUpdateConfigurationModel).subscribe(resp => {
								if (resp.isSuccessful) {
									this.notificationService.success({
										title: "Конфигурация",
										message: "Сообщение в TMS Gate отправлено",
										notificationType: NotificationType.Toast
									});
								} else {
									this.notificationService.error({
										title: "Конфигурация",
										message: "Ошибка при отправке сообщение в TMS Gate: " + resp.errorDescription,
										notificationType: NotificationType.Toast
									});
								}
							});
						}
					} else {
						if (!!changePwfStatusToastModel && !!changePwfStatusToastModel.showFailure) {
							this.notificationService.error({
								title: changePwfStatusToastModel.failureTitle,
								message: !!changePwfStatusToastModel.failureMessage ? changePwfStatusToastModel.failureMessage : x.errorDescription,
								notificationType: NotificationType.Toast
							});
						}
					}

				}, () => {
					this.switchOffTranslationFlag(transition.transitionId);
					this.mobileWorkflowsLoading = false;
				});
		}
	}

	switchOnTranslationFlag(transitionId: number) {
		this.workflowTransitionsInProgress.push(transitionId);
	}

	switchOffTranslationFlag(transitionId: number) {
		const index = this.workflowTransitionsInProgress.indexOf(transitionId, 0);
		if (index > -1) {
			this.workflowTransitionsInProgress.splice(index, 1);
		}
	}

	switchOnActivityTranslationFlag(transitionId: number) {

		const index = this.activityTransitionsInProgress.indexOf(transitionId, 0);
		if (index === -1) {
			this.activityTransitionsInProgress.push(transitionId);
		}

	}

	switchOffActivityTranslationFlag(transitionId: number) {
		const index = this.activityTransitionsInProgress.indexOf(transitionId, 0);
		if (index > -1) {
			this.activityTransitionsInProgress.splice(index, 1);
		}
	}

	get cardHeight(): number {
		return window.innerHeight - 200;
	}

	get activityCardHeight(): number {
		return window.innerHeight - 210;
	}

	get allowEditingByStatus(): boolean {
		return this.canEditForm && this.entityViewModel.entity.statusKindId != 3;
	}

	get isExpertise(): boolean {
		return this.entityViewModel.entity.requestTypeId === RequestType.expertise;
	}

	performerUserGroupChange(groupId: number, setPerformerUserNull: boolean): void {

		if (!groupId)
			return;

		this.loadPerformerUsers(groupId, true);
	}

	loadPerformerUsers(groupId: number, setPerformerUserNull: boolean): void {

		if (!groupId)
			return;

		this.usersService.getUsersByGroup(groupId).subscribe(data => this.performersByGroup = data);

		if (setPerformerUserNull) {
			this.entityViewModel.entity.performerUserId = null;
		}
	}

	authorUserGroupChange(groupId: number, setAuthorUserNull: boolean): void {
		if (!groupId)
			return;

		this.usersService.getUsersByGroup(groupId).subscribe(data => this.authorsByGroup = data);

		if (setAuthorUserNull) {
			this.entityViewModel.entity.authorUserId = null;
		}
	}

	private clearTsp(): void {
		this.entityViewModel.entity.customerObjectName = null;
		this.entityViewModel.entity.customerObjectAddress = null;
		this.currentCustomerObjectAddress = null;
		this.entityViewModel.entity.customerObjectDistanceFromCityKm = null;
		this.entityViewModel.entity.merchantContragentName = null;
		this.entityViewModel.entity.merchantContragentAddress = null;
		setTimeout(() => {
			if (this.daDataLegalAddressControl && this.daDataRealAddressControl) {
				this.daDataLegalAddressControl.initValue(null);
				this.daDataRealAddressControl.initValue(null);
			}
		}, 1000);
	}

	clientChange(value: number): void {
		this.loadAgreementsByClient(value);
		this.loadMerchantsByClient(value);
	}

	customerObjectChange(value: number): void {
		this.loadServiceCentersByCustomerObject(value);
		this.loadTidsByByCustomerObject(value);
		this.bindTspComputedFields();
		setTimeout(() => {
			if (this.daDataRealAddressControl) {
				this.daDataRealAddressControl.initValue(this.currentCustomerObjectAddress);
			}
		}, 1000);
	}

	serviceCenterChange(value: number): void {
		//this.loadEngeneersByServiceCenter(value, new Date());
	}

	loadAgreementsByClient(clientId: number) {

		if (!clientId)
			return;

		var state: DataSourceRequestState = {
			skip: 0,
			take: null,
			filter: { logic: "and", filters: [{ field: "ContragentId", operator: "eq", value: clientId }] }
		};

		this.lookupService.getData("agreements", state).subscribe(data => this.agreements = data);
	}

	loadMerchantsByClient(clientId: number, computeTspFields: boolean = true) {

		if (!clientId)
			return;

		this.bindTspComputedFields(computeTspFields);
	}

	loadCustomerObjectsByMerchant(merchantId: number, computeTslFields: boolean = true) {

		if (!merchantId)
			return;

		this.bindTspComputedFields(computeTslFields);
	}

	loadServiceCentersByCustomerObject(customerObjectId: number) {

		this.currentServiceCenterId = this.entityViewModel.entity.serviceCenterId;

		if (!customerObjectId)
			return;

		this.customerObjectsService.getServiceCenters(customerObjectId).subscribe(data => {

			const defaultServiceCenters = data.filter(x => x.isDefault);
			this.defaultServiceCenter = defaultServiceCenters.length > 0 ? defaultServiceCenters[0] : null;

			if (!this.entityViewModel.entity.serviceCenterId && this.defaultServiceCenter != null) {
				this.entityViewModel.entity.serviceCenterId = this.defaultServiceCenter.id;
			}
		});
	}

	loadEngeneersByServiceCenter(serviceCenterId: number, plannedStartDate: Date, mustBeStorekeeper: boolean = false, afterLoadAction: Function = null) {

		if (this.entityViewModel.entity.requestTypeId !== <number>RequestType.warehouseRefill
			&& this.entityViewModel.entity.requestTypeId !== <number>RequestType.warehouseTransfer
			&& this.entityViewModel.entity.requestTypeId !== <number>RequestType.foreighWarehouseTransfer
			&& this.entityViewModel.entity.requestTypeId !== <number>RequestType.internalWarehouseTransfer
			&& this.entityViewModel.entity.requestTypeId !== <number>RequestType.warehousePosting
			&& !serviceCenterId) {
			this.emptyEngineersMessage = 'Не указан сервисный центр';
			return;
		}

		// this.serviceCentersService
		// 	.getEngineers(serviceCenterId, this.entityViewModel.entity.requestId)
		// 	.subscribe(data => {
		// 		this.engeneers = data;
		// 	});

		if (this.entityViewModel.entity.initTypeId === <number>RequestInitTypeEnum.blockChain &&
			this.entityViewModel.entity.requestTypeId !== <number>RequestType.warehouseRefill &&
			this.entityViewModel.entity.requestTypeId !== <number>RequestType.issueFromWarehouse &&
			this.entityViewModel.entity.requestTypeId !== <number>RequestType.warehouseTransfer &&
			this.entityViewModel.entity.requestTypeId !== <number>RequestType.foreighWarehouseTransfer &&
			this.entityViewModel.entity.requestTypeId !== <number>RequestType.internalWarehouseTransfer &&
			this.entityViewModel.entity.requestTypeId !== <number>RequestType.warehousePosting &&
			this.entityViewModel.entity.requestTypeId !== <number>RequestType.receiveToWarehouse &&
			this.entityViewModel.entity.requestTypeId !== <number>RequestType.issueAndReceiveToWarehouse &&
			this.entityViewModel.entity.lastActivityId === null &&
			(this.entityViewModel.entity.commonData === null ||
				!!!this.entityViewModel.entity.commonData.isUnknownDistanceRequest) &&
			this.entityViewModel.entity.partnerStatusAlias !== 'takenToWorkSP') {
			this.emptyEngineersMessage = `В заявке указан статус в системе партнера отличный от 'takenToWorkSP'`;
			return;
		}

		if (this.entityViewModel.entity.initTypeId === <number>RequestInitTypeEnum.blockChain &&
			this.entityViewModel.entity.requestTypeId !== <number>RequestType.warehouseRefill &&
			this.entityViewModel.entity.requestTypeId !== <number>RequestType.issueFromWarehouse &&
			this.entityViewModel.entity.requestTypeId !== <number>RequestType.warehouseTransfer &&
			this.entityViewModel.entity.requestTypeId !== <number>RequestType.foreighWarehouseTransfer &&
			this.entityViewModel.entity.requestTypeId !== <number>RequestType.internalWarehouseTransfer &&
			this.entityViewModel.entity.requestTypeId !== <number>RequestType.warehousePosting &&
			this.entityViewModel.entity.requestTypeId !== <number>RequestType.receiveToWarehouse &&
			this.entityViewModel.entity.requestTypeId !== <number>RequestType.issueAndReceiveToWarehouse &&
			this.entityViewModel.entity.lastActivityId === null &&
			this.entityViewModel.entity.commonData !== null &&
			!!this.entityViewModel.entity.commonData.isUnknownDistanceRequest &&
			this.entityViewModel.entity.partnerStatusAlias !== 'stopedSLA') {
			this.emptyEngineersMessage = `В заявке указан статус в системе партнера отличный от 'stopedSLA'`;
			return;
		}

		let engineersMultipleSDateModel = new ServiceCenterEngineersData(this.entityViewModel.entity.requestId, serviceCenterId, plannedStartDate, mustBeStorekeeper);

		let engineersMultipleScModel = new ServiceCenterEngineers([engineersMultipleSDateModel]);

		this.loadingEngineers = true;
		this.serviceCentersService.engineersMultipleSc(engineersMultipleScModel).subscribe(x => {
			if (x.isSuccessful) {
				this.engineers = x.data;
				this.engineersGrouped = orderBy(<GroupResult[]>groupBy(this.engineers, [{ field: "region" }]), [{ field: "value", dir: "asc" }]);
			}

			if (afterLoadAction != null)
				afterLoadAction();

			this.loadingEngineers = false;
		});
	}

	public plannedStartDateChange(value: Date, activity: Activity): void {
		this.plannedDateSubject.next({ date: value, activity: activity });
	}

	public refreshEngineers(activity: Activity): void {
		activity.performerUserId = null;

		const activityTypeId = activity.activityTypeId;
		if (activityTypeId === <number>ActivityType.engineer) {
			this.loadEngeneersByServiceCenter(this.entityViewModel.entity.serviceCenterId, activity.planStartDate);
		} else if (activityTypeId === <number>ActivityType.techSupport) {
			this.loadingEngineers = true;

			// UserGroupId: 3 - Техподдержка (2-я линия)
			this.activitiesService.getTechSupportUsers()
				.subscribe(result => {
					this.engineers = result.map(user => {
						const engineer = {
							engineerUserId: user.id,
							name: user.name,
						} as EngineerLookupModel;

						return engineer;
					});

					this.engineersGrouped = orderBy(this.engineers/*<GroupResult[]>groupBy(this.engineers, [{ field: "region" }])*/, [{ field: "value", dir: "asc" }]);

					this.loadingEngineers = false;
				});
		} else if (activityTypeId === <number>ActivityType.warehouse) {
			this.loadEngeneersByServiceCenter(this.entityViewModel.entity.serviceCenterId, activity.planStartDate, true, null);
		}
	}

	public isStartedExpertiseActivityStatus(activity: Activity): boolean {
		return activity && (activity.statusId === <number>ActivityStatusEnum.startExpertise ||
			activity.statusId === <number>ActivityStatusEnum.workCompleted ||
			activity.statusId === <number>ActivityStatusEnum.expertiseInProgress);
	}

	public isEngineerDisabled(itemArgs: { dataItem: EngineerLookupModel, index: number }) {
		return !itemArgs.dataItem.isAvailable;
	}

	public handleEngineersFilter(query: string): void {
		this.loadingEngineers = true;
		this.engineerFilterSubject.next(query);
	}

	loadTidsByByCustomerObject(customerObjectId: number) {

		if (!customerObjectId)
			return;

		this.customerObjectsService.getTids(customerObjectId).subscribe(data => {
			this.customerObjectTids = data;
		});
	}

	bindTspComputedFields(computeTspFields: boolean = true) {

		if (!computeTspFields)
			return;

		if (this.entityViewModel.entity.merchantId != null) {
			this.merchantsService.getContragentByMerchant(this.entityViewModel.entity.merchantId).subscribe(c => {
				this.entityViewModel.entity.merchantContragentName = c.name;
				this.entityViewModel.entity.merchantContragentAddress = c.address;
			});
		}
	}

	getNameById(data: KeyValueObject[], id: number): string {
		return this.appService.getNameById(data, id);
	}

	getActivityTypeNameById(activityTypeId: number): string {
		const activityType = this.activityTypes.filter(x => x.id === activityTypeId);
		return activityType.length === 1 ? activityType[0].name : '';
	}

	public loadRequestServices() {
		this.requestsService.getRequestServices(Number(this.entityId)).subscribe(requestServices => {
			this.entityViewModel.entity.requestServices = requestServices;
		});
	}

	onAttachmentsAdded(attachments: UploadFile[]) {
		this.entityViewModel.entity.requestAttachments = attachments;
	}

	downloadFile(attachment: Attachment) {
		this.attachmentsService.getAttachmentById(attachment.attachmentId).subscribe(blob => {
			saveAs(blob, attachment.attachmentName, { type: 'application/octet-stream' });
		});
	}

	showFileTab(): void {
		this.activeIdString = "ngb-tab-3";
	}

	saveChanges(): void {
		this.dataSaving = true;
		if (this.entityViewModel.entity.finishDateFactLocal) {
			this.requestsService.checkNewFinishFactDate(this.entityViewModel.entity.requestId, this.entityViewModel.entity.finishDateFactLocal)
				.subscribe(result => {

					if (result.isSuccessful) {
						this.checkSavingNewServiceCenter();
					} else {
						this.notificationService
							.confirmation({
								message: result.errorDescription,
								type: 'question',
								cancelButtonText: 'Отмена',
								showCloseButton: true,
								showConfirmButton: false
							}, () => { }, () => this.dataSaving = false);
					}
				});
		}
		else {
			this.checkSavingNewServiceCenter();
		}
	}

	processSavingWithNewServiceCenter() {
		const serviceCenterChanged = this.currentServiceCenterId !== this.entityViewModel.entity.serviceCenterId;
		const defaultServiceCenterDiffers = this.defaultServiceCenter && this.entityViewModel.entity.serviceCenterId !== this.defaultServiceCenter.id;
		if (serviceCenterChanged && (defaultServiceCenterDiffers || !this.defaultServiceCenter)) {
			this.notificationService.confirmation({
				message: 'Сервисный центр был изменен. \nСменить сервисный центр в объекте TID?',
				type: 'question',
				confirmButtonText: 'Да',
				cancelButtonText: 'Нет',
				showCloseButton: true
			},
				() => {
					this.entityViewModel.entity.changeDefaultCustomerObjectServiceCenterBelonging = true;
					this.processSaving();
				},
				() => {
					this.entityViewModel.entity.changeDefaultCustomerObjectServiceCenterBelonging = false;
					this.processSaving();
				});
		}
		else {
			this.processSaving();
		}
	}

	checkSavingNewServiceCenter(){
		const serviceCenterChanged = this.currentServiceCenterId !== this.entityViewModel.entity.serviceCenterId;
		if (serviceCenterChanged){
			this.dataService
				.canChangeServiceCenter(this.entityViewModel.entity.requestId)
				.subscribe(response => {
					if (response.data.isDeviceOnEngineer){
						this.notificationService.error({
							title: 'Невозможно изменить Сервисный центр',
							message: 'В заявке есть оборудование выданное инженеру или установленное на ТСП, по которому не завершён процесс возврата оборудования на склад. Завершите процесс возврата и повторите смену СЦ снова',
							notificationType: NotificationType.SweetAlert
						});

						this.entityForm.controls['serviceCenter'].setErrors({ 'Невозможно изменить Сервисный центр': true });
						this.dataSaving = false;
					}
					else if (response.data.isDeviceDismantled){
						this.notificationService.error({
							title: 'Невозможно изменить Сервисный центр',
							message: 'В заявке есть демонтированное оборудование или успешно завершенные активности, смена СЦ невозможна',
							notificationType: NotificationType.SweetAlert
						});

						this.entityForm.controls['serviceCenter'].setErrors({ 'Невозможно изменить Сервисный центр': true });
						this.dataSaving = false;
					}
					else if (response.data.isNeedBackActivity) {
						this.notificationService.confirmation({
							message: 'По заявке есть назначенная незавершенная активность. Она будет отменена. Продолжить?',
							type: 'question',
							confirmButtonText: 'Продолжить',
							cancelButtonText: 'Нет',
							showCloseButton: true
						},
						() => {
							this.checkDevicesReserve(response.data.isDevicesReserve);
						},
						() => {
							this.entityForm.controls['serviceCenter'].setErrors({ 'Невозможно изменить Сервисный центр': true });
							this.dataSaving = false;
						});
					} else {
						this.checkDevicesReserve(response.data.isDevicesReserve);
					}
				});	
		} else {
			this.checkDevicesReserve(false);
		}	
	}

	checkDevicesReserve(hasReserve: boolean){
		if (hasReserve){
			this.notificationService.confirmation({
				message: 'В заявке есть зарезервированное оборудование. Будет ли производиться перерезервирование со склада нового СЦ?',
				type: 'question',
				confirmButtonText: 'Оставить текущий резерв',
				cancelButtonText: 'Аннулировать текущий резерв',
				showCloseButton: true
			},
				() => {
					this.processSavingWithNewServiceCenter();
				},
				() => {
					this.entityViewModel.entity.cancelReserve = true;
					this.processSavingWithNewServiceCenter();
				});
		} else {
			this.processSavingWithNewServiceCenter();
		}
	}

	processSaving() {
		const changedControls = Object.keys(this.entityForm.controls).filter(key => this.entityForm.controls[key].dirty);
		if (this.entityViewModel.entity.statusKindId == 3 && changedControls.includes('finishDateFactLocal')) {
			this.requestsService.changeFinishDateFact(this.entityViewModel.entity.requestId, this.entityViewModel.entity.finishDateFactLocal)
				.subscribe(result => {
					this.dataSaving = false;

					if (!result.isSuccessful)
						return;

					this.appService.MarkFormPristine(this.entityForm);
					this.canEditForm = false;
				})
		}

		const saveChanges = this.isCustomerObjectAddressChanged() ? () => this.saveOnCustomerObjectAddressChanged() : () => super.saveChanges();
		saveChanges();

		this.loadServiceCentersByCustomerObject(this.entityViewModel.entity.customerObjectId);
	}

	saveOnCustomerObjectAddressChanged(): void {
		forkJoin(
			this.requestsService.checkNeedAddressChangeUserConfirmation(+this.entityIdConst, this.currentCustomerObjectAddress),
			this.requestsService.getRequestIdsByCustomerObjectIfEngineerNotArrived(this.entityViewModel.entity.customerObjectId))
			.subscribe(([needAddressChangeUserConfirmation, requestIds]) => {
				if (needAddressChangeUserConfirmation) {
					var template =
						`<div style="text-align: left;">
							Вы изменили адрес объекта ТСП<br><br>
							<b>Старый адрес</b>: {{oldAddress}}<br><br>
							<b>Новый адрес</b>: {{newAddress}}<br><br>
							Для отмены сохранения закройте окно
						</div>`;

					const message = this.templateEngineService.render(template, { oldAddress: this.entityViewModel.entity.customerObjectAddress, newAddress: this.currentCustomerObjectAddress } as any);

					this.notificationService
						.confirmation({
							message: message,
							type: 'question',
							confirmButtonText: 'Уведомить клиента',
							cancelButtonText: 'Сохранить без уведомления',
							showCloseButton: true,
							enableHtml: true,
						},
							() => {
								this.entityViewModel.entity.customerObjectAddress = this.currentCustomerObjectAddress;
								this.entityViewModel.entity.notifyCustomerObjectAddressChange = true;
								this.afterAddressChangeCheck(requestIds);
							},
							() => {
								this.entityViewModel.entity.customerObjectAddress = this.currentCustomerObjectAddress;
								this.afterAddressChangeCheck(requestIds);
							},
							() => {
								this.dataSaving = false;
							});
				}
				else {
					this.entityViewModel.entity.customerObjectAddress = this.currentCustomerObjectAddress;
					this.afterAddressChangeCheck(requestIds);
				}
			});
	}

	afterAddressChangeCheck(requestIds: number[]): void {
		requestIds = requestIds.filter(id => id !== this.entityViewModel.entity.requestId);
		if (requestIds.length > 0) {
			var template =
				`<div>Для текущего объекта ТСП есть заявки без выезда инженера. Сменить этим заявкам адрес тип и фактическую удалённость?</div>
							  <div style="width: 65%; margin: auto; text-align: left;">
									{{#data}}
										<a href="/requests/{{requestId}}" target="__blank" style="display: block; margin-bottom: 4px;">{{requestId}}</a>
									{{/data}}
								</div>`;

			const message = this.templateEngineService.render(template, requestIds.map(id => { return { requestId: id } as any; }));

			this.notificationService
				.confirmation({
					message: message,
					type: 'question',
					confirmButtonText: 'Да',
					cancelButtonText: 'Нет',
					showCloseButton: true,
					enableHtml: true,
				},
					() => {
						this.entityViewModel.entity.requestIdsToChangeCustomerObjectAddress = requestIds;
						this.afterCustomerObjectsCheck();
					},
					() => {
						this.entityViewModel.entity.requestIdsToChangeCustomerObjectAddress = [];
						this.afterCustomerObjectsCheck();
					});
		}
		else {
			this.afterCustomerObjectsCheck();
		}
	}

	afterCustomerObjectsCheck(): void {
		super.saveChanges()
		this.dataSaving = false;
	}

	afterSaveChanges() {
		super.afterSaveChanges();
		this.dataService.getById(this.entityViewModel.entity.requestId).subscribe(vm => {
			this.dataBind(vm);
			setTimeout(() => {
				this.engubatorRequestService.getById(this.entityViewModel.entity.requestId).subscribe(vm => {
					this.engubatorViewModel = vm;
				});
			}, 2000);
		});
	}

	public showNewMerchantWindow() {
		const dialogRef = this.dialogService.open({ content: MerchantFormFull, width: '100%', height: '80%' });

		this.merchantForm = <MerchantFormFull>dialogRef.content.instance;
		this.merchantForm.header = 'Новый ТСП';
		this.merchantForm.showCancelButton = true;
		this.merchantForm.request = this.entityViewModel.entity;
		this.merchantForm.isUpdateRequest = true;
		this.merchantForm.clientAgreementId = this.entityViewModel.entity.agreementId;

		this.merchantForm.afterSaveChangesEvent.subscribe((newTid: KeyValueObject) => {
			window.location.reload();
		});
	}

	public showTidEditModal(): void {
		if (this.entityViewModel.entity.acceptedTidNumbers.length > 0) {
			const dialogRef = this.dialogService.open({ content: EditTidsComponent, width: this.isMobileDevice ? '90%' : '40%', height: '50%' });

			const editTidsCard = <EditTidsComponent>dialogRef.content.instance;

			editTidsCard.tids = this.entityViewModel.entity.acceptedTidNumbers;

			editTidsCard.save.subscribe(tids => {
				this.entityViewModel.entity.acceptedTidNumbers = tids;
				this.appService.MarkFormDirty(this.entityForm);
			});
		}
	}

	private delay(ms: number) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	private async DetectTspFilled() {
		let i = 60;
		while (!this.legalEntity.nativeElement.value) {
			await this.delay(1000);
			i--;
			if (i === 0) {
				break;
			}
		}

		await this.delay(3000);

		this.merchantForm.closeDialog();
	}

	public showNewCustomerObjectWindow() {

		const dialogRef = this.dialogService.open({ content: CustomerObjectForm, width: '100%' });

		const customerObjectForm = <CustomerObjectForm>dialogRef.content.instance;
		customerObjectForm.header = 'Новый объект ТСП';
		customerObjectForm.showCancelButton = true;

		customerObjectForm.afterSaveChangesEvent.subscribe((newCustomerObject: KeyValueObject) => {
			this.loadServiceCentersByCustomerObject(this.entityViewModel.entity.customerObjectId);
			this.loadEngeneersByServiceCenter(this.entityViewModel.entity.serviceCenterId, new Date());
			this.loadTidsByByCustomerObject(this.entityViewModel.entity.customerObjectId);
			this.entityViewModel.entity.customerObjectId = newCustomerObject.id;
			this.bindTspComputedFields();
			setTimeout(() => {
				if (this.daDataRealAddressControl) {
					this.daDataRealAddressControl.initValue(this.currentCustomerObjectAddress);
				}
			}, 1000);

			this.saveChanges();
		});
	}

	get canSelectOnlyWarehouseActivityTypeUninstallNew(): boolean {
		let lastEngAct = this.activities
			.sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1))
			.find(f => f.activityTypeId === <number>ActivityType.engineer);

		return (!this.isMkInitType
			&& [RequestTypeEnum.uninstall, RequestTypeEnum.service].includes(this.entityViewModel.entity.requestTypeId)
			&& this.activities.length > 0
			&& !!lastEngAct
			&& lastEngAct.statusId === <number>ActivityStatusEnum.awaitingReturnToWarehouse);
	}

	get canSelectOnlyWarehouseActivityTypeClassic(): boolean {
		let lastEngAct = this.activities
			.sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1))
			.find(f => f.activityTypeId === <number>ActivityType.engineer);

		return (!this.isMkInitType
			&& ((this.entityViewModel.entity.requestTypeId === RequestTypeEnum.install
				|| this.entityViewModel.entity.requestTypeId === RequestTypeEnum.installWithDismantle
				|| this.entityViewModel.entity.requestTypeId === RequestTypeEnum.service
				|| this.entityViewModel.entity.requestTypeId === RequestTypeEnum.changeConfig
				|| this.entityViewModel.entity.requestTypeId === RequestTypeEnum.installCRE
				|| this.entityViewModel.entity.requestTypeId === RequestTypeEnum.changeConfigCRE)

				&& this.activities.length > 0
				&& !!lastEngAct
				&& lastEngAct.statusId === <number>ActivityStatusEnum.returnOfNotWorkingEquipment)

			||

			(this.entityViewModel.entity.requestTypeId === RequestTypeEnum.changeConfig
				&& this.activities.length > 0
				&& !!lastEngAct
				&& lastEngAct.statusId === <number>ActivityStatusEnum.awaitingReturnToWarehouse)
		);
	}

	get canSelectOnlyWarehouseActivityTypePsbWarehouseRefill(): boolean {
		return this.isContragentPsb
			&& this.entityViewModel.entity.requestTypeId === <number>RequestTypeEnum.warehouseRefill;
	}

	get canSelectOnlyWarehouseActivityTypeAtmAllianceZenitWarehouseRefill(): boolean {
		return this.isContragentAtmAllianceZenit
			&& this.entityViewModel.entity.requestTypeId === <number>RequestTypeEnum.warehouseRefill;
	}

	get canSelectOnlyWarehouseActivityTypeMk(): boolean {
		return (this.isMkInitType
			&& (this.entityViewModel.entity.requestTypeId === RequestTypeEnum.uninstallOld
				|| this.entityViewModel.entity.requestTypeId === RequestTypeEnum.changeConfig
				|| this.entityViewModel.entity.requestTypeId === RequestTypeEnum.expertise

				|| this.entityViewModel.entity.requestTypeId === RequestTypeEnum.install
				|| this.entityViewModel.entity.requestTypeId === RequestTypeEnum.installWithDismantle
				|| this.entityViewModel.entity.requestTypeId === RequestTypeEnum.service
				|| this.entityViewModel.entity.requestTypeId === RequestTypeEnum.serviceOrReplace

				|| this.entityViewModel.entity.requestTypeId === RequestTypeEnum.installCRE)

			&& this.activities.length > 0
			&& this.activities[this.activities.length - 1].activityTypeId === <number>ActivityType.engineer
			&& (this.activities[this.activities.length - 1].statusId === <number>ActivityStatusEnum.engineerGaveDevices
				|| this.activities[this.activities.length - 1].statusId === <number>ActivityStatusEnum.returnOfNotWorkingEquipment))

			||

			(this.isMkInitType
				&& (this.entityViewModel.entity.requestTypeId === RequestTypeEnum.receiveToWarehouse
					|| this.entityViewModel.entity.requestTypeId === RequestTypeEnum.issueAndReceiveToWarehouse
					|| this.entityViewModel.entity.requestTypeId === RequestTypeEnum.issueFromWarehouse))

			||

			(this.isMkInitType
				&& this.entityViewModel.entity.requestTypeId === RequestTypeEnum.remoteInstall

				&& this.activities.length > 0
				&& this.activities[this.activities.length - 1].activityTypeId === <number>ActivityType.engineer
				&& this.activities[this.activities.length - 1].statusId === <number>ActivityStatusEnum.awaitingDeliveryOfEquipment);
	}

	get selectWarehouseActivityType(): boolean {
		let lastEngAct = this.activities
			.sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1))
			.find(f => f.activityTypeId === <number>ActivityType.engineer);

		return (this.entityViewModel.entity.customerContragentId !== <number>CustomerEnum.vtb &&
			(this.entityViewModel.entity.requestTypeId === RequestType.install
				|| this.entityViewModel.entity.requestTypeId === RequestType.service
				|| this.entityViewModel.entity.requestTypeId === RequestType.changeConfig
				|| this.entityViewModel.entity.requestTypeId === RequestType.installCRE)
			&& this.activities.length > 0
			&& !!lastEngAct
			&& lastEngAct.activityTypeId == <number>ActivityType.engineer
			&& lastEngAct.statusId == <number>ActivityStatusEnum.awaitingDeliveryOfEquipment)
	}

	createNewActivity(request: Request, e: any): any {

		var plannedDateSubject = this.plannedDateSubject;

		let activityTypeId = <number>ActivityType.engineer;

		if (this.canSelectOnlyWarehouseActivityTypeMk
			|| this.canSelectOnlyWarehouseActivityTypeClassic
			|| this.canSelectOnlyWarehouseActivityTypePsbWarehouseRefill
			|| this.canSelectOnlyWarehouseActivityTypeAtmAllianceZenitWarehouseRefill
			|| this.canSelectOnlyWarehouseActivityTypeUninstallNew
			|| this.selectWarehouseActivityType) {
			activityTypeId = <number>ActivityType.warehouse;
		}

		return function () {

			let now = new Date();
			let utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
			let local = new Date(utc.getTime() + (request.utcTimezoneShift * 60 * 60 * 1000));
			let planStartDate = local;
			if (request.plannedDateLocal) {
				planStartDate = new Date(request.plannedDateLocal);
			}

			const activity = new Activity({
				requestId: request.requestId,
				createdDate: local,
				planStartDate: planStartDate,
				dueDate: request.slaDueDate,
				dueDateMoscow: request.slaDueDateMoscow,
				dueDateLocal: request.slaDueDateLocal,
				activityTypeId: activityTypeId,
			});

			plannedDateSubject.next({ date: local, activity: activity });

			return activity;
		};
	}

	public activitySaveHandler(eventArgs: any) {
		const isNew = !!eventArgs.dataItem.activityId;

		if (isNew) {
			this.activitiesService.getById(eventArgs.dataItem.activityId).subscribe(async activityOld => {
				let engineerIdNew = eventArgs.dataItem.performerUserId;
				let engineerIdOld = activityOld.entity.performerUserId;

				if ((activityOld.entity.statusId === 13 || (activityOld.entity.statusId === 24 && this.entityViewModel.entity.customerContragentId == 1055)) && activityOld.entity.changePerformerUserId !== eventArgs.dataItem.changePerformerUserId) {
					const newUser = this.engineers.filter(p => p.engineerUserId === eventArgs.dataItem.changePerformerUserId)[0];
					this.notificationService.confirmation({
						title: this.entityName,
						message: `Вы пытаетесь сменить инженера после того, как оборудование уже было выдано. Активность перейдет в состояние ожидания действий по приему оборудования со с
						тороны нового инженера ${newUser.name}`,
						type: 'question',
						confirmButtonText: 'Ок',
						cancelButtonText: 'Отменить',
						showCloseButton: true
					}, () => {
						this.updateActivity(eventArgs.dataItem, engineerIdNew, engineerIdOld);
					}, () => { });
				} else {
					this.updateActivity(eventArgs.dataItem, engineerIdNew, engineerIdOld);
				}

			});

		} else {
			this.AddNewActivity(<Activity>eventArgs.dataItem);
			eventArgs.dataItem = null;
		}
	}

	private AddNewActivity(activity: Activity): void {

		this.loadingActivities = true;

		this.activitiesService.create(activity, NotificationType.SweetAlert, true).subscribe(x => {

			var needReloadActivities = true;
			let needReloadRequest = this.isMkInitType
				&& this.entityViewModel.entity.requestTypeId === <number>RequestType.remoteInstall;

			if (!x.isSuccessful) {

				if (x.code === 948) {
					this.showGroupError(x.errorDescription);

				} else if (x.code === <number>ExceptionCode.engineerHasDifferentThanRequestServiceCenterException) {

					this.notificationService
						.confirmation({
							message: x.errorDescription,
							type: 'question',
							confirmButtonText: 'Изменить Исполнителя (СЦ)',
							cancelButtonText: 'Отмена',
							showCloseButton: false
						},
							() => {
								activity.skipExceptions.push(<number>ExceptionCode.engineerHasDifferentThanRequestServiceCenterException);
								this.AddNewActivity(activity);
								return;
							},
							() => {
								needReloadActivities = false;
								this.loadingActivities = false;
								return;
							}
						);


				} else {
					this.notificationService.error({
						title: 'Ошибка',
						message: x.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

				this.loadingActivities = false;

			} else {
				if (needReloadActivities)
					this.loadActivities(this.entityViewModel.entity.requestId);

				if (needReloadRequest)
					this.loadRequest(+this.entityId);
			}
		});

	}

	private updateActivity(dataItem: any, engineerIdNew: number, engineerIdOld: number): void {
		let commentModel: SetCommentModel = null;

		if (engineerIdOld !== engineerIdNew && !this.entityViewModel.entity.isInGroupExt) {
			const dialogRef = this.dialogService.open({
				content: SetComment,
				width: this.isMobileDevice ? '90%' : '500px',
				title: "Укажите причину смены инженера"
			});

			const customerObjectForm = <SetComment>dialogRef.content.instance;

			if (customerObjectForm.setCommentModel == null) {
				customerObjectForm.setCommentModel = new SetCommentModel('');
			}

			customerObjectForm.setCommentModel.commentKind = CommentKindEnum.internal;

			customerObjectForm.onContinueEvent.subscribe((setCommentModel: SetCommentModel) => {
				commentModel = setCommentModel;

				this.updateActivityCore(dataItem, () => {

					let uploadAttachments: UploadFile[] = [];
					this.requestsService.addComment(
						{
							text: `Причина смены инженера: ${commentModel.commentText}`,
							requestId: this.entityViewModel.entity.requestId,
							activityId: dataItem.activityId,
							uploadAttachments: uploadAttachments,
							notifyCustomer: false,
							notifyEngineer: false,
							engineerNotifyWays: [],
							commentKind: CommentKindEnum.system
						}).subscribe(x => { });

					this.requestsService.addComment(
						{
							text: `Причина смены инженера по активности [${dataItem.activityId}]: ${commentModel.commentText}`,
							requestId: this.entityViewModel.entity.requestId,
							activityId: null,
							uploadAttachments: uploadAttachments,
							notifyCustomer: false,
							notifyEngineer: false,
							engineerNotifyWays: [],
							commentKind: CommentKindEnum.system
						}).subscribe(x => { });

					this.loadActivities(this.entityViewModel.entity.requestId);

				});

			});
		} else {

			this.updateActivityCore(dataItem, () => {
				this.loadActivities(this.entityViewModel.entity.requestId);
			});

		}
	}

	updateActivityCore(activity: Activity, onSuccess: Function) {

		this.activitiesService.update(activity, 'Наряд обновлен', true).subscribe(x => {
			if (x.isSuccessful) {

				if (!!onSuccess)
					onSuccess();

			} else {
				if (x.code === 948) {
					this.showGroupError(x.errorDescription);
				} else if (x.code === <number>ExceptionCode.engineerHasDifferentThanRequestServiceCenterException) {

					this.notificationService
						.confirmation({
							message: x.errorDescription,
							type: 'question',
							confirmButtonText: 'Изменить Исполнителя (СЦ)',
							cancelButtonText: 'Отмена',
							showCloseButton: false
						},
							() => {

								if (activity.skipExceptions == null)
									activity.skipExceptions = [];

								activity.skipExceptions.push(<number>ExceptionCode.engineerHasDifferentThanRequestServiceCenterException);
								this.updateActivityCore(activity, onSuccess);
								return;
							},
							() => {
								this.loadingActivities = false;
								return;
							}
						);


				} else {
					this.notificationService.error({
						title: 'Ошибка',
						message: x.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
			}
		});

	}

	private showGroupError(msg: string): void {
		let errors = (<RequestGroupDto[]>this.nodes).map(m => new MultipleError(m.requestId, ""));

		const dialogRef = this.dialogService.open({ content: MultipleOperationErrorResultComponent, width: '50%', height: '50%' });

		const multipleOperationErrorResultComponent = <MultipleOperationErrorResultComponent>dialogRef.content.instance;
		multipleOperationErrorResultComponent.errors = errors;
		multipleOperationErrorResultComponent.heightPercent = 53;
		multipleOperationErrorResultComponent.headerText = msg;
	}

	private activitySave() {

	}

	backToActivitiesClick() {
		this.showActivitiesGrid = true;
		this.showActivityDetails = false;
	}

	showActivityById(activityId: number) {

		if (this.isMobileDevice) {
			this.showActivitiesGrid = false;
			this.showActivityDetails = true;
		}

		this.selectedActivities = [];
		let activity = this.activities.find(x => x.activityId == activityId);
		if (!this.statusIsCanceled(activity)){
			this.selectedActivities.push(activityId);
		}
		this.onSelectedActivityChange();
	}

	public isActivitySelected = (e: RowArgs) => this.selectedActivities.indexOf(e.dataItem.activityId) >= 0;

	public activityRemoveHandler({ dataItem }: any) {
		this.activitiesService.remove(`${(<Activity>dataItem).activityId}`, 'Наряд удален', '').subscribe(x => {

			if (x.isSuccessful) {
				this.loadActivities(this.entityViewModel.entity.requestId);
				this.selectedActivities = [];
				this.selectedActivity = null;
			}
		});
	}




	public onSelectedActivityChange() {

		if (!this.selectedActivityId)
			return;

		this.activitiesService.getById(this.selectedActivityId).subscribe(x => {
			this.selectedActivity = x.entity;

			if (this.activityComments)
				this.activityComments.loadComments(0, this.getSelectedActivityPerformerUserId());

			this.fitActivitiesColumns();

			if (this.activeIdString == "activities-tab")
				this.loadFines()
		});
	}

	public onActivityAttachmentsAdded(attachments: UploadFile[]) {
		const uploadModel = new UploadAttachmentsModel({ requestId: this.entityViewModel.entity.requestId, activityId: this.selectedActivityId, attachments });

		this.attachmentsService.upload(uploadModel)
			.pipe(tap(x => {
				switch (x.type) {
					case HttpEventType.UploadProgress:
						this.uploadPercentages = Math.round(x.loaded / (x.total != null ? x.total : 0) * 100);

						if (this.uploadPercentages == 100)
							this.uploadPercentages = null;
				}
			}))
			.pipe(switchMap(() => this.activitiesService.getById(this.selectedActivityId)))
			.subscribe(x => {
				this.selectedActivity = x.entity;
				this.activityComments.loadComments(0, this.getSelectedActivityPerformerUserId());
				this.uploadInProgress = false;
			});
	}

	actionHasHistoryWithTypeId(typeId: number): boolean {

		if (!this.selectedActivity) {
			return false;
		}

		var result = this.selectedActivity.history.some(x => x.activityActionTypeId === typeId);

		return result;

	}

	getActionHistoryDate(typeId: number): Date {

		if (!this.selectedActivity) {
			return null;
		}

		const filtered = this.selectedActivity.history.filter(x => x.activityActionTypeId === typeId);

		if (filtered.length === 0) {
			return null;
		}

		return filtered[0].actionDate;
	}

	changeActivityStatus(newStatusId: number) {

		if (this.entityViewModel.entity.initTypeId !== 3 &&
			newStatusId == 4 &&
			!this.actionHasHistoryWithTypeId(6)) {

			this.notificationService.confirmation({
				title: this.entityName,
				message: 'Вы не отметили прибытие на объект. Работы ведутся без выезда?',
				type: 'question',
				confirmButtonText: 'Да',
				cancelButtonText: 'Нет',
				showCloseButton: true
			}, () => {

				this.changeActivityStatusCore(newStatusId, '');

			});

			return;
		}

		if (newStatusId != 3 && newStatusId != 7) {
			this.changeActivityStatusCore(newStatusId, '');
			return;
		}

		const dialogRef = this.dialogService.open({ content: SetComment, width: '100%' });

		const customerObjectForm = <SetComment>dialogRef.content.instance;

		customerObjectForm.onContinueEvent.subscribe((setCommentModel: SetCommentModel) => {
			this.changeActivityStatusCore(newStatusId, setCommentModel.commentText);
		});
	}

	changeActivityStatusCore(newStatusId: number, comment: string) {
		this.switchOnActivityTranslationFlag(newStatusId);

		this.activitiesService.changeStatus(this.selectedActivityId, newStatusId, comment).subscribe(() => {
			this.loadActivities(+this.entityId);
			this.onSelectedActivityChange();
			this.loadRequest(this.entityViewModel.entity.requestId);
			this.activityDevices.refreshDevicesList();
			this.switchOffActivityTranslationFlag(newStatusId);
		});
	}

	onActivityChangeStatus(transition: ActivityWorkflowTransition) {
		this.loadActivities(this.entityViewModel.entity.requestId);
		this.onSelectedActivityChange();
		this.loadRequest(this.entityViewModel.entity.requestId);
		if (this.activityDevices) {
			this.activityDevices.refreshDevicesList();
		}
	}

	get createNewActivityOperation(): any {
		return function () {
			var activityOperation = new ActivityOperation();
			activityOperation.activityId = this.selectedActivityId;
			return activityOperation;
		};
	}

	public activityOperationSaveHandler({ dataItem }: any) {

		var isNew = !!dataItem.activityOperationId;

		dataItem.activityId = this.selectedActivityId;

		var observable = isNew
			? this.activityOperationsService.update(dataItem, 'Работа обновлена')
			: this.activityOperationsService.create(dataItem);

		observable.subscribe(x => {
			if (x.isSuccessful) {
				this.onSelectedActivityChange();
			} else {
				dataItem = null;
			}
		});
	}

	public activityOperationRemoveHandler({ dataItem }: any) {
		this.activityOperationsService.remove(`${(<ActivityOperation>dataItem).activityOperationId}`, 'Работа удалена', '').subscribe(x => {

			if (x.isSuccessful) {
				this.onSelectedActivityChange();
			}
		});
	}

	getDateCellText(value: Date): string {
		return this.appService.getDateCellText(value, true);
	}

	ngOnDestroy(): void {

		if (this.plannedDateSubject != null)
			this.plannedDateSubject.unsubscribe();

		if (this.engineerFilterSubject != null)
			this.engineerFilterSubject.unsubscribe();

	}

	public get isCheckDeviceAvailableToMoveVisible(): boolean {
		switch (this.entityViewModel.entity.requestTypeId) {
			case <number>RequestType.confirmRepair:
				return this.entityViewModel.entity.statusId === <number>RequestStatus.pendingApproval
					|| this.entityViewModel.entity.statusId === <number>RequestStatus.awaitingConfirmationFromEth;
			case <number>RequestType.warehouseTransfer:
			case <number>RequestType.foreighWarehouseTransfer:
			case <number>RequestType.internalWarehouseTransfer:
				return this.entityViewModel.entity.statusId === <number>RequestStatus.AssignedToWarehouse
					|| this.entityViewModel.entity.statusId === <number>RequestStatus.awaitingCollectionForMoving
					|| this.entityViewModel.entity.statusId === <number>RequestStatus.collectionForMoving;
			default:
				break;
		}
	}

	public get isNotEngineer(): boolean {
		return !this.securityService.isEngineer() || this.securityService.isAdmin() || this.securityService.isSCUser();
	}

	public get isEngineer(): boolean {
		return this.securityService.isEngineer();
	}

	public get isOnlyEngineer(): boolean {
		return this.securityService.isOnlyEngineer();
	}

	public get isAdmin(): boolean {
		return this.securityService.isAdmin();
	}

	public get isCoordinator(): boolean {
		return this.securityService.isLanterCoordinator() || this.securityService.isSCPartnerCoordinator();
	}

	private fitActivitiesColumns(): void {
		this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {

			if (this.activitiesGrid) {
				this.activitiesGrid.autoFitColumns();
			}

		});
	}

	public get isMkInitType(): boolean {
		return this.entityViewModel.entity.initTypeId === <number>RequestInitTypeEnum.blockChain;
	}

	public get isServiceRequest(): boolean {
		return this.entityViewModel.entity.requestTypeId !== <number>RequestType.warehouseRefill
			&& this.entityViewModel.entity.requestTypeId !== <number>RequestType.warehousePosting
			&& this.entityViewModel.entity.requestTypeId !== <number>RequestType.warehouseTransfer
			&& this.entityViewModel.entity.requestTypeId !== <number>RequestType.foreighWarehouseTransfer
			&& this.entityViewModel.entity.requestTypeId !== <number>RequestType.internalWarehouseTransfer
			&& this.entityViewModel.entity.requestTypeId !== <number>RequestType.issueFromWarehouse
			&& this.entityViewModel.entity.requestTypeId !== <number>RequestType.receiveToWarehouse
			&& this.entityViewModel.entity.requestTypeId !== <number>RequestType.issueAndReceiveToWarehouse;
	}

	public get isVisibleForBlockchain(): boolean {
		if (this.entityViewModel.entity.initTypeId === <number>RequestInitTypeEnum.blockChain) {
			switch (this.entityViewModel.entity.requestTypeId) {
				case <number>RequestType.install:
				case <number>RequestType.installWithDismantle:
				case <number>RequestType.service:
				case <number>RequestType.changeConfig:
				case <number>RequestType.uninstall:
				case <number>RequestType.uninstallOld:
				case <number>RequestType.expertise:
				case <number>RequestType.serviceOrReplace:
				case <number>RequestType.expressExpertise:
				case <number>RequestType.groupRequest:
					return this.canChangeEngineerByActivity;

				default:
					return false;
			}
		} else {
			return true;
		}

	}

	public get isRequestInEditableSpecState(): boolean {
		return this.entityViewModel.requestSpecStatusIdsAllowedForModifying.includes(this.entityViewModel.entity.statusId);
	}

	public get canEditPerformerInSelectedSpecStates(): boolean {
		return this.securityService.canEditRequestPerformerInSelectedSpecStates();
	}


	openActivitiesButtomSheet() {
		this.activityWorkflow.openActivitiesButtomSheet();
	}

	openContragentContactsButtomSheet() {
		this.contragentContactsButtomSheetVisibility = true;

		const bottomSheetRef = this.bottomSheet.open(this.contragentContactsButtomSheetList);

		bottomSheetRef.afterOpened().subscribe(() => {
			this.contragentContactsButtomSheetVisibility = true;
		});

		bottomSheetRef.afterDismissed().subscribe(() => {
			this.contragentContactsButtomSheetVisibility = false;
		});
	}

	callToCustomerService(contragentContact: Contact, event: MouseEvent) {

		this.bottomSheet.dismiss();
		event.preventDefault();

		this.usersService.callToCustomerService(this.entityViewModel.entity.requestId, contragentContact).subscribe(x => {

			if (x.isSuccessful) {

				this.notificationService.success({
					title: '',
					message: `Устанавливается соединение... Ожидайте входящего звонка`,
					notificationType: NotificationType.SweetAlert
				});

			}

		});

	}

	openContragentContactsButtomSheetListCancel(event: MouseEvent) {
		this.bottomSheet.dismiss();
		event.preventDefault();
	}

	get isCanEditRequestDevices() {
		return this.securityService.isCanEditRequestDevices() || this.securityService.isAdmin();
	}

	get isPartnerCoordinator() {
		return this.securityService.isSCPartnerCoordinator();
	}

	get coordiantorCanEdit() {
		return !this.securityService.isSCPartnerCoordinator() || (this.securityService.isSCPartnerCoordinator() && !this.entityViewModel.entity.serviceCenterIsAlienGroup);
	}

	get isPartnerEngineer() {
		return this.securityService.isSCPartnerEngineer();
	}

	get isOnlyStorekeeper() {
		return this.securityService.isOnlyStorekeeper();
	}

	activityPerformerUserDisabled(activity: Activity): boolean {
		return !!activity
			&& !!activity.performerUserId
			&& this.engineers.map(m => m.engineerUserId).includes(activity.performerUserId)
			&& this.isOnlyStorekeeper;
	}

	performerChangeUser(dataItem: Activity) {
		if (dataItem.statusId === 13 || (dataItem.statusId == 24 && this.entityViewModel.entity.customerContragentId == 1055))
			return dataItem.changePerformerUserId;
		else {
			if (this.isOnlyStorekeeper && this.engineers.map(m => m.engineerUserId).includes(this.securityService.currentUser.userId)) {
				dataItem.performerUserId = this.securityService.currentUser.userId;
			}

			return dataItem.performerUserId;
		}
	}

	changePerformerUser(dataItem: Activity, value) {
		if (dataItem.statusId === 13 || (dataItem.statusId == 24 && this.entityViewModel.entity.customerContragentId == 1055))
			dataItem.changePerformerUserId = value;
		else
			dataItem.performerUserId = value;
	}

	nodeClick(node: any): void {
		if (node.requestId !== +this.entityId) {
			window.open(`/requests/${node.requestId}`, '_blank');
		}
	}

	goToActivity(activityId: number) {
		this.activeIdString = "activities-tab";
		this.showActivityById(activityId);
	}

	parseMultiTimezoneDate(dateTime: any) {

		if (dateTime == null) {
			return '';
		}

		var parsedDate = new Date();

		if (dateTime instanceof Date) {
			parsedDate = dateTime;
		} else if (typeof dateTime === 'string') {
			if (dateTime.startsWith("1753")) {
				return '';
			}

			parsedDate = this.intl.parseDate(dateTime);
		}

		return `${this.intl.formatDate(parsedDate, 'dd.MM.yyyy HH:mm')} ${this.entityViewModel.entity.utcTimezoneShiftString}`;
	}

	processFinishDateFactLocal() {
		if (this.entityViewModel.entity.finishDateFactLocal)
			this.entityViewModel.entity.finishDateFactLocal = this.appService.parseDateTime(this.entityViewModel.entity.finishDateFactLocal);
	}

	isSpecialRightClaimValid(claim: string): boolean {
		return this.securityService.isSpecialRightClaimValid(claim);
	}

	get isUserClient(): boolean {
		return this.securityService.isClient();
	}

	get hasEditRequestClaim(): boolean {
		return this.securityService.hasClaim('cat-requests-edit');
	}

	get isContragentPsb(): boolean {
		return this.entityViewModel.entity.customerContragentId === <number>CustomerEnum.psb;
	}

	get isContragentAtmAllianceZenit(): boolean {
		return this.entityViewModel.entity.customerContragentId === <number>CustomerEnum.atmAllianceZenit;
	}

	get tspTabDisabled(): boolean {
		if (this.entityViewModel.entity.requestTypeId === <number>RequestType.collision
			|| this.entityViewModel.entity.requestTypeId === <number>RequestType.registration) {
			return true;
		}
		return false;
	}

	get isCreateUserRequest(): boolean {
		if (this.entityViewModel.entity.requestTypeId === <number>RequestType.registration) {
			return true;
		}
		return false;
	}

	get equipmentTabDisabled(): boolean {
		if (this.entityViewModel.entity.requestTypeId === <number>RequestType.registration) {
			return true;
		}
		return false;
	}

	get servicesTabDisabled(): boolean {
		if (this.entityViewModel.entity.requestTypeId === <number>RequestType.collision
			|| this.entityViewModel.entity.requestTypeId === <number>RequestType.registration) {
			return true;
		}
		return false;
	}

	get activitiesTabDisabled(): boolean {
		if (this.entityViewModel.entity.requestTypeId === <number>RequestType.collision
			|| this.entityViewModel.entity.requestTypeId === <number>RequestType.registration) {
			return true;
		}
		return false;
	}

	get callsTabDisabled(): boolean {
		return this.entityViewModel.entity.requestTypeId === <number>RequestType.collision;
	}

	get statusSlaTabDisabled(): boolean {
		if (this.entityViewModel.entity.requestTypeId === <number>RequestType.collision
			|| this.entityViewModel.entity.requestTypeId === <number>RequestType.registration) {
			return true;
		}
		return false;
	}

	getCommentObject(json: string): any {
		return JSON.parse(json);
	}

	public get customerContact(): CustomerContact {
		if (this.entityViewModel.entity && this.entityViewModel.entity.customerContacts && this.entityViewModel.entity.customerContacts.length > 0) {
			return this.entityViewModel.entity.customerContacts[0];
		}
		return null;
	}

	callToTsp(customerContact: CustomerContact) {
		this.usersService.callToTsp(customerContact).subscribe(x => {

			if (x.isSuccessful) {

				this.notificationService.success({
					title: '',
					message: `Устанавливается соединение... Ожидайте входящего звонка`,
					notificationType: NotificationType.SweetAlert
				});

			}

		});

	}

	callToPerformer(activity: Activity) {
		let performerContact: PerformerContact = {
			requestId: activity.requestId,
			contactName: activity.performerUserName,
			contactPhone: activity.performerPhoneNumber,
			contactExtPhone: activity.performerExtPhoneNumber
		}

		this.usersService.callToPerformer(performerContact).subscribe(x => {
			if (x.isSuccessful) {
				this.notificationService.success({
					title: '',
					message: `Устанавливается соединение... Ожидайте входящего звонка`,
					notificationType: NotificationType.SweetAlert
				});
			}
		});
	}

	get isSidebarCollapsed(): boolean {
		return !<boolean>this.layoutService.isCollapsed();
	}

	public get filteredContragentContacts(): Contact[] {
		let filteredContacts = this.entityViewModel.entity.contragentContacts.filter(v => !this.entityViewModel.entity.customerContacts.find(c => c.contactPhone.replace(/\D/g, '') === v.description.replace(/\D/g, '')));
		return filteredContacts;
	}

	get isRequestClassicOrKkmLogic(): boolean {
		return (this.entityViewModel.entity.initTypeId !== 3 || this.entityViewModel.entity.requestTypeId === 15 || this.entityViewModel.entity.requestTypeId === 16) && this.isCanEditRequestDevices && !this.isUserClient;
	}

	get isServiceLogic(): boolean {
		return (this.isMkInitType
			&& (this.entityViewModel.entity.requestTypeId === <number>RequestTypeEnum.service
				|| ((this.entityViewModel.entity.requestTypeId === <number>RequestTypeEnum.issueFromWarehouse
					|| this.entityViewModel.entity.requestTypeId === <number>RequestTypeEnum.issueAndReceiveToWarehouse
					|| this.entityViewModel.entity.requestTypeId === <number>RequestTypeEnum.receiveToWarehouse)
					&& this.entityViewModel.entity.baseMspRequestTypeId === <number>RequestTypeEnum.service))
		)
			&& (this.isSpecialRightClaimValid("EquipmentManagementInMkRequests")
				|| this.isAdmin);
	}

	get isEnabledForRequestDeviceComponent(): boolean {
		return this.entityViewModel.entity && this.entityViewModel.entity.statusKindId !== 3;
	}

	onLegalAddressChange(value: AddressSuggestion) {
		this.entityViewModel.entity.merchantContragentAddress = value.address;

		if (!!value) {
			this.appService.MarkFormDirty(this.entityForm);
		} else {
			this.appService.MarkFormPristine(this.entityForm);
		}
	}

	onRealAddressChange(value: AddressSuggestion) {
		this.currentCustomerObjectAddress = value.address;

		if (!!value) {
			this.appService.MarkFormDirty(this.entityForm);
		} else {
			this.appService.MarkFormPristine(this.entityForm);
		}
	}

	get isAddressValid(): boolean {
		if (this.activeIdString == "tsp" && !!this.daDataLegalAddressControl && !!this.daDataRealAddressControl) {
			return this.daDataLegalAddressControl.isValid() && this.daDataRealAddressControl.isValid();
		} else {
			return true;
		}
	}

	public tidsVirtual: any = {
		itemHeight: 28,
	}

	public skillsToString(skills: KeyValueObject[]): string {
		return skills.map(m => m.name).join(', ');
	}

	private isCustomerObjectAddressChanged(): boolean {
		return this.entityViewModel.entity.customerObjectAddress != this.currentCustomerObjectAddress;
	}

	private get isServiceCategoriesAccess() {
		return this.securityService.hasClaim("cat-servicecategories-read") || this.securityService.hasClaim("cat-servicecategories-edit");
	}

	loadServiceCategories(): void {

		if (!!this.entityViewModel.entity.agreementId && this.isServiceCategoriesAccess) {
			this.serviceCategoriesService.getByAgreementId(this.entityViewModel.entity.agreementId).subscribe(result => this.serviceCategories = result.data);
		}
		else {
			this.serviceCategories = [];
		}
	}

	get isPhoneVisibileInMobileVersion(): boolean {
		return this.securityService.isPhoneVisibileInMobileVersion();
	}

	get isRegisteredAndCompleted(): boolean {
		if (this.entityViewModel.entity.statusId === <number>RequestStatus.Completed
			&& this.entityViewModel.entity.requestTypeId === <number>RequestType.registration)
			return true;
		return false;
	}

	//#region Fines

	get showFines() {
		return this.securityService.hasClaim('cat-Fines-read');
	}

	get canEditFines() {
		return this.showFines && this.securityService.hasClaim('cat-Fines-add') && this.securityService.hasClaim('cat-Fines-edit');
	}

	public loadFines() {
		setTimeout(() => {
			this.activityFineTypesService.getFineTypes().subscribe(fineTypes => {
				this.activityFinesService.getFines(this.entityViewModel.entity.requestId).subscribe(fines => {
					this.finesComponent.loadFines(fineTypes, fines);
				});
			});
		}, !!this.entityViewModel ? 0 : 1000);

		// для теста
		// setTimeout(() => {
		// 	this.finesComponent.loadFines([], []);
		// });
	}

	//#endregion

	createOnlyUnknownDistanceActivityMkClick(): void {
		this.activitiesService.createUnknownDistanceActivityMk(this.entityViewModel.entity.requestId).subscribe(res => {
			if (res.isSuccessful) {
				this.loadActivities(this.entityViewModel.entity.requestId);
				this.loadRequest(+this.entityId);
			}
		});
	}

	public getLastActivityPerformerUserId(): number {
		return !!this.activities && !!this.activities[0] ? this.activities[0].performerUserId : null;
	}

	public getSelectedActivityPerformerUserId(): number {
		return !!this.selectedActivity ? this.selectedActivity.performerUserId : null;
	}

	public get descriptionIsValid(): boolean {
		if (this.contragentsNotAllowedEmptyDesctiption.find(x => x.id == this.entityViewModel.entity.customerContragentId)) {
			return new RegExp('[а-яА-ЯёЁa-zA-Z]{5,}').test(this.entityViewModel.entity.description);
		}
		return true;
	}

	get activityEditButtonIsVisible(): boolean {
		return this.entityViewModel.entity
			&& this.entityViewModel.entity.statusKindId !== 3
			&& this.isVisibleForBlockchain
			&& this.coordiantorCanEdit
			&& (this.isPartnerCoordinator || !this.isPartnerEngineer);
	}

	editHandler({ dataItem }: any): void {
		this.refreshEngineers(dataItem);
	}

	public getServiceCenter(): string {
		let thisSc = this.serviceCenters.find(x => x.id === this.entityViewModel.entity.serviceCenterId);
		if (thisSc) {
			return thisSc.name;
		}
		return '';
	}

	private initSignalR(entityId: string) {
		this.signalR.startConnection().subscribe(() => {
			this.signalR.addToGroup(entityId);
			this.signalR.getOrCreateMessageListener(this.terminalConfigAddedMessageListener).subscribe(status => {
				this.notificationService.success({
					title: 'Обновление данных после подготовки конфигурации',
					message: "Статус заявки и комментарии обновились!",
					notificationType: NotificationType.SweetAlert
				});
				if (status) {
					this.entityViewModel.entity.statusName = status;
					this.changeDetectorRef.detectChanges();
				}
			});
		});
	}

	private shortcutReturnEquipment(transition: WorkflowTransition) {
		var hasEmptyNomenclature = this.requestUninstallDevices.some(x => !x.nomenclature);

		if (hasEmptyNomenclature) {
			this.notificationService.error({
				title: 'Отсутствует номенклатура',
				message: `Системе не удалось произвести идентификацию модели оборудования. Для использования в других заявках, дождитесь финальной обработки заявки и перевода в "Выполнено".`,
				notificationType: NotificationType.SweetAlert
			});
		}
		this.requestsService.shortcutReturnEquipment(this.selectedActivity.activityId)
			.subscribe((res) => {
				if (res.isSuccessful) {
					this.changeStatusCore(transition, new ChangeStatusModel(+this.entityId, transition.requestStatusToId))
				}
			});
	}

	onCommendAddedEvent(event: any) {
		if (this.needToReturnBackToLanter()) {
			this.externalService.requestBackToLanterSD(this.entityViewModel.entity.externalId).subscribe(() => {
				this.loadRequest(+this.entityId);
			})
		}
	}

	needToReturnBackToLanter(): boolean {
		return this.entityViewModel.entity.statusId == <number>RequestStatus.Stopped
			&& this.entityViewModel.entity.externalId
			&& (this.securityService._currentUser && this.securityService._currentUser.contragentId === this.entityViewModel.entity.customerContragentId);
	}

	statusIsStopped(): boolean{
		if (this.entityViewModel.entity.statusId === <number>RequestStatus.Stopped){
			return true;
		}
		return false;
	}

	
	get stoppingTitle(): String {
		if (this.entityViewModel.entity.reasonForStopping) {
			return `${this.entityViewModel.entity.reasonForStopping} Остановлена до: ${this.parseMultiTimezoneDate(this.entityViewModel.entity.stoppedUntilLocal)}`;
		}

		return "-";
	}

	private getSenderAndRecipientWarehouseIds(): void {
		if (this.entityViewModel.entity.senderWarehouseAreaId) {
			this.warehousesService.getWarehouseByAreaId(this.entityViewModel.entity.senderWarehouseAreaId).subscribe(data => {
				this.entityViewModel.entity.senderWarehouseId = data.warehouseId;
			});
		}

		if (this.entityViewModel.entity.recipientWarehouseAreaId) {
			this.warehousesService.getWarehouseByAreaId(this.entityViewModel.entity.recipientWarehouseAreaId).subscribe(data => {
				this.entityViewModel.entity.recipientWarehouseId = data.warehouseId;
			});
		}
	}

	activityCallback(context: RowClassArgs) {
		return context.dataItem && context.dataItem.statusId == <number>ActivityStatusEnum.cancelled
			? 'greyRow' : {};
	}

	public statusIsCanceled(item: any): boolean {
		return item && item.statusId == <number>ActivityStatusEnum.cancelled;
	}
}
